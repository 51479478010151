import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ContentSpinner from "../shared/ContentSpinner";

export const LoaderContext = React.createContext();

class LoaderContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  setIsLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;
    return (
      <LoaderContext.Provider value={{ ...this, ...this.state }}>
        <Modal
          className="process-model"
          show={isLoading}
          backdrop="static"
          centered
          keyboard={false}
        >
          <Modal.Body>
            <ContentSpinner />
            <h5>Please wait...</h5>
          </Modal.Body>
        </Modal>
        {children}
      </LoaderContext.Provider>
    );
  }
}

export default LoaderContextProvider;
