import axios from "axios";
import { useEffect, useState, useMemo, useContext } from "react";
import {
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import AxiosInstance from "../AxiosRequest";
import { useWallet } from "@solana/wallet-adapter-react";
import { depositSOL } from "../methods/nftcreator";
import * as anchor from "@project-serum/anchor";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentSpinner from "../shared/ContentSpinner";
import {toast, ToastContainer} from "react-toastify";

import _ from "lodash";
import { clusterSolanaApiUrl } from "../constants";
import { copyText } from "../utils";
import { WalletContext } from "../Context/WalletContextProvider";
import { handleDeployNFTS } from "./CommonMethode";
import { authContext } from "../Context/Web3AuthContext";
import {
  extractAudioFromZip,
  extractDocumentFromZip,
  extractVideoFromZip,
  uploadVideosFromBlob,
  videoExtractutil,
} from "./zipExtract";
import JSZip from "jszip";
import getProvider from "../methods/getProvider";

export default function PreviewNFTs({
  formik,
  state,
  previous,
  next,
  setSubMenuIndex,
  contentMenu,
  setContentMenu,
  subMenuIndex,
  setImageFile,
  setState,
  publicKey,
}) {
  const [nftDetails, setNftDetail] = useState([{}]);
  const [isShowNFTDetailModal, setIsShowNFTDetailsModal] = useState(false);
  const [isShowDepositeModal, setIsShowDepositeModal] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [deposit, setDeposit] = useState(false);
  const [collection, setCollection] = useState();
  const [nfts, setNFTs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDepositeLoading, setIsDepositeLoading] = useState(false);
  const [generatMsgModal, setGeneratMsgModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [depositeModal, setIsDepositeModal] = useState(false);
  const [redeployModal, setRedployeModal] = useState(false);
  const [isShowNFTDetail, setIsShowNFTDetails] = useState([false]);
  const { selectedChain, connectWallet, setActiveChain } =
    useContext(WalletContext);
  const [videoUrls, setVideoUrls] = useState([]);
  let intervalHandle = null;

  useEffect(() => {
    loadmore();
    getData();
  }, [subMenuIndex]);

  const wallet = getProvider();
  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    };
  }, [wallet]);

  useEffect(() => {
    if (state.collection.type === "video") {
      extractVideo();
    }
  }, []);

  const extractVideo = async () => {
    try {
      setVideoModal(true);
      const url = `${process.env.REACT_APP_API_URL}/${
        state.collection.folderName.split("/")[2]
      }/video/0.zip`;

      const zip = new JSZip();

      const response = await fetch(url);

      console.log("response video = ", response);

      const archive = await zip.loadAsync(response.blob());

      const videosAll = await videoExtractutil(archive);

      const finalVideos = await Promise.all(videosAll.videos);

      console.log("final video", finalVideos);

      if (finalVideos.length !== state.collection.no_of_nfts) {
        return toast.error(
          "The number of videos and the number of nfts are not same"
        );
      } else {
        if (videoUrls.length === 0) {
          // const response = await uploadVideosFromBlob(
          //   finalVideos,
          //   state.collection,
          //   videosAll
          // );
          // console.log("response", response);
          // setVideoUrls(response);

          let objUrls = [];
          finalVideos.forEach((blob, index) => {
            // const video = videoRefs.current[index];

            // if (video) {
            const objectURL = URL.createObjectURL(blob);
            // video.src = objectURL;
            // }
            objUrls.push(objectURL);
          });

          setVideoUrls(objUrls);
        }
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setVideoModal(false);
    }
  };

  // console.log("video blobs = ", videoUrls);

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const connection = new anchor.web3.Connection(clusterSolanaApiUrl);

  const getNFTMetadata = async (index) => {
    let array = nftDetails;
    if (nfts?.length > 0) {
      if (index + 1 <= nfts.length) {
        for (let i = 0; i < nfts.length; i++) {
          if (index === i) {
            const data = await axios.get(
              `${process.env.REACT_APP_API_URL}/${state.folderName}/assets/${index}`
            );
            array[index] = data.data;
          } else {
            array[i] = {};
          }
        }
      } else {
        array[index] = {};
      }
    }
    setNftDetail([...array]);
  };

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setState({
          ...state,
          collection: response.data.data,
        });
        setWalletAddress(response.data.data.new_wallet);
        setDeposit(response.data.data.readyToDeploy);
        setCollection(response.data.data);
      }
    }
  };

  const handleSubmit = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data.data.chain === "Solana") {
        if (response.data.data.deployed_success === true) {
          setIsDepositeLoading(false);
          setContentMenu(!contentMenu);
          clearInterval(intervalHandle);
          setGeneratMsgModal(false);
          next();
        } else if (response.data.data.deployed_failed === true) {
          setIsDepositeLoading(false);
          clearInterval(intervalHandle);
          setGeneratMsgModal(false);
          setRedployeModal(true);
        }
      } else {
        if (response.data.data.deploy_json === true) {
          clearInterval(intervalHandle);
          let uri = response.data.data.uri;
          await handleDeployNFTS(
            publicKey,
            state.collection.creator,
            setState,
            state,
            setContentMenu,
            setIsDepositeLoading,
            setGeneratMsgModal,
            uri
          );
          if (state.collection.currentStep === 5) {
            setIsDepositeLoading(false);
            setGeneratMsgModal(false);
            setContentMenu(!contentMenu);
          }
        }
      }
    }
  };

  const checkBalance = async () => {
    let isNetworkChange = false;
    if (selectedChain === "Solana") {
      if (deposit) {
        setIsDepositeLoading(true);
        setRedployeModal(false);
        setGeneratMsgModal(true);
        await AxiosInstance.post("/nftcreator/deploy", {
          _id: collection._id,
        });
        intervalHandle = setInterval(() => {
          handleSubmit();
        }, 10000);
      } else {
        setIsShowDepositeModal(true);
      }
    } else {
      try {
        setIsDepositeLoading(true);
        if (state.collection.type === "layered") {
          if (window.ethereum.networkVersion !== state.collection.network_id) {
            try {
              isNetworkChange = await connectWallet(
                selectedChain,
                setActiveChain,
                setIsDepositeLoading
              );
            } catch (err) {
              console.log(err);
              setIsDepositeLoading(false);
              toast.error("Something went wrong");
            }
          } else {
            isNetworkChange = true;
          }
          if (isNetworkChange) {
            try {
              setRedployeModal(false);
              setGeneratMsgModal(true);
              await AxiosInstance.post("/nftcreator/deploy", {
                _id: collection._id,
              });
              intervalHandle = setInterval(() => {
                handleSubmit();
              }, 10000);
            } catch (err) {
              setGeneratMsgModal(false);
              throw err;
            }
          }
        } else if (state.collection.type === "video") {
          const url = `${process.env.REACT_APP_API_URL}/${
            state.collection.folderName.split("/")[2]
          }/video/0.zip`;
          await extractVideoFromZip(
            url,
            state,
            publicKey,
            state.collection.creator,
            setState,
            setContentMenu,
            videoUrls,
            setIsDepositeLoading
          );
        } else if (state.collection.type === "music") {
          const url = `${process.env.REACT_APP_API_URL}/${
            state.collection.folderName.split("/")[2]
          }/music/0.zip`;
          await extractAudioFromZip(
            url,
            state,
            publicKey,
            state.collection.creator,
            setState,
            setContentMenu,
            setIsDepositeLoading
          );
        } else if (state.collection.type === "document") {
          const url = `${process.env.REACT_APP_API_URL}/${
            state.collection.folderName.split("/")[2]
          }/document/0.zip`;
          await extractDocumentFromZip(
            url,
            state,
            publicKey,
            state.collection.creator,
            setState,
            setContentMenu,
            setIsDepositeLoading
          );
        }
        if (state.currentStep === 5) {
          setIsDepositeLoading(false);
        }
      } catch (err) {
        setIsDepositeLoading(false);
        console.log(err);
        throw err;
      }
    }
  };

  const handleDeposit = async () => {
    try {
      setIsDepositeLoading(true);
      const amountDeposit = Number(
        Number(
          (state.collection?.no_of_nfts
            ? state.collection.no_of_nfts
            : formik.values.noOfNfts) *
            0.0018 +
            0.05 +
            0.0018
        ).toFixed(5)
      );
      console.log(wallet.publicKey);
      console.log(connection, anchorWallet, walletAddress);
      const signature = await depositSOL(
        connection,
        anchorWallet,
        walletAddress,
        amountDeposit
      );
      console.log(signature);
      if (signature) {
        setIsShowDepositeModal(false);
        setIsDepositeModal(true);
        setTimeout(async () => {
          const tokenAmount = await connection.getBalance(
            new anchor.web3.PublicKey(walletAddress)
          );
          const balance = Number(Number(tokenAmount / 1e9).toFixed(5));
          console.log(balance, " - ", typeof balance);
          console.log(amountDeposit, " - ", typeof amountDeposit);
          if (balance >= amountDeposit) {
            console.log(balance);
            const response = await AxiosInstance.get(
              `/nftcreator/readyToDeploy?id=${state.collection._id}&sig=${signature}`
            );
            if (response.status === 200) {
              if (response.data && response.data.data) {
                setWalletAddress(response.data.data.new_wallet);
                setDeposit(response.data.data.readyToDeploy);
                setSubMenuIndex(2);
                setIsDepositeModal(false);
                setIsDepositeLoading(false);
                toast.success(
                  "Deposit success. You can continue to deploy your collection."
                );
              } else {
                setIsDepositeLoading(false);
                toast.error(
                  "Something went wrong. Please try again later."
                );
              }
            } else {
              setIsDepositeLoading(false);
              toast.error("Something went wrong. Please try again later.");
            }
          } else {
            setIsDepositeLoading(false);
            toast.error("Something went wrong. Please try again later.");
          }
        }, 15000);
      } else {
        setIsShowDepositeModal(false);
        setIsDepositeModal(true);
        setTimeout(async () => {
          const tokenAmount = await connection.getBalance(
            new anchor.web3.PublicKey(walletAddress)
          );
          const balance = Number(Number(tokenAmount / 1e9).toFixed(5));
          console.log(balance, " - ", typeof balance);
          console.log(amountDeposit, " - ", typeof amountDeposit);
          if (balance >= amountDeposit) {
            console.log(balance);
            const response = await AxiosInstance.get(
              `/nftcreator/readyToDeploy?id=${state.collection._id}&sig=${signature}`
            );
            if (response.status === 200) {
              if (response.data && response.data.data) {
                setWalletAddress(response.data.data.new_wallet);
                setDeposit(response.data.data.readyToDeploy);
                setSubMenuIndex(2);
                setIsDepositeModal(false);
                setIsDepositeLoading(false);
                toast.success(
                  "Deposit success. You can continue to deploy your collection."
                );
              } else {
                setIsDepositeLoading(false);
                toast.error(
                  "Something went wrong. Please try again later."
                );
              }
            } else {
              setIsDepositeLoading(false);
              toast.error("Something went wrong. Please try again later.");
            }
          } else {
            setIsDepositeLoading(false);
            toast.error("Something went wrong. Please try again later.");
          }
        }, 15000);
      }
    } catch (err) {
      setIsDepositeLoading(false);
      toast.error("Something went wrong. Please try again later.");
      throw err;
    }
  };

  const loadmore = () => {
    setIsLoading(true);
    const newNFTS = [];
    const minLength =
      nfts.length + 20 < state.collection.no_of_nfts
        ? 20
        : state.collection.no_of_nfts - nfts.length;
    for (let i = 0; i < minLength; i++) {
      newNFTS.push(i);
    }
    setNFTs([...nfts, ...newNFTS]);
    setIsLoading(false);
  };

  const getRarityValue = (layerName, background_color) => {
    let rarity = 0;
    for (let i = 0; i < state.collection.layers.length; i++) {
      if (state.collection.layers[i].layerName === layerName) {
        for (let j = 0; j < state.collection.layers[i].attributes.length; j++) {
          if (
            state.collection.layers[i].attributes[j].background_color ==
            background_color
          ) {
            rarity = state.collection.layers[i].attributes[j].rarity;
          }
        }
      }
    }
    return rarity;
  };

  const getName = () => {
    if (subMenuIndex === 0) {
      return "Upload Context";
    } else if (subMenuIndex === 1) {
      return "Generated NFTS";
    } else if (subMenuIndex === 2) {
      return "Deploy";
    }
  };

  const handleNFTDetails = (index) => {
    let array = isShowNFTDetail;
    if (nfts?.length > 0) {
      if (index + 1 <= nfts.length) {
        for (let i = 0; i < nfts.length; i++) {
          if (index === i) {
            array[index] = !array[index];
          } else {
            array[i] = false;
          }
        }
      } else {
        array[index] = !array[index];
      }
    }
    setIsShowNFTDetails([...array]);
  };

  return (
    <>
    <ToastContainer />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <div className="mt-2 col-8 ms-3">
              <div className="title-text mt-2 mb-2">{getName()}</div>
            </div>

            <div>
              {!deposit && (
                <button
                  className="mx-2 btnGray"
                  onClick={() => {
                    setSubMenuIndex(0);
                    setImageFile([]);
                  }}
                >
                  Previous
                </button>
              )}
              <button
                className="mx-2 btnGray"
                onClick={checkBalance}
                disabled={isDepositeLoading}
              >
                {isDepositeLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-1"
                  />
                )}
                {selectedChain !== "Solana"
                  ? "Deploy"
                  : deposit
                  ? "Deploy"
                  : "Deposit"}
              </button>
            </div>
          </div>
          <div className="grayBox">
            <InfiniteScroll
              dataLength={nfts.length}
              next={loadmore}
              hasMore={
                nfts.length >= state.collection.no_of_nfts ? false : true
              }
              loader={<></>}
              style={{ overflow: "hidden" }}
            >
              <Row>
                {nfts?.length > 0 &&
                  collection &&
                  new Array(Math.ceil(nfts?.length / 4))
                    .fill(0)
                    .map((value, index) => {
                      return (
                        <>
                          <div className="testing d-flex">
                            {nfts
                              .slice(index * 4, (index + 1) * 4)
                              .map((nftImage, i) => {
                                return (
                                  <Col
                                    key={nftImage.name}
                                    md={3}
                                    className={`px-3`}
                                  >
                                    {(state.collection.type === "document" ||
                                      state.collection.type === "music") && (
                                      <div
                                        className={`mt-4 ${
                                          isShowNFTDetail[index * 4 + i]
                                            ? "nft-card-details"
                                            : "gray-border"
                                        }`}
                                        // style={{ cursor: "pointer" }}
                                      >
                                        <div className=" d-flex justify-content-center align-items-center">
                                          <img
                                            src={
                                              state.collection.type === "music"
                                                ? "https://gateway.pinata.cloud/ipfs/QmWh9PGx7JrXQ5w4pVfxhMmCe9rqgGUrourWD1WMTjN5kw"
                                                : "https://gateway.pinata.cloud/ipfs/QmSVddcmy4ohnvD34EQiKtC7gZPNZuAUiJ415ar33z1w7N"
                                            }
                                            alt=""
                                            className="card-image mb-3"
                                          />
                                        </div>
                                        <div className="">
                                          <div
                                            className="mb-2 deploy-card-title d-flex justify-content-center align-items-center"
                                            style={{ fontSize: "15px" }}
                                          >
                                            {state.collection.collection_name} #
                                            {index * 4 + i + 1}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {state.collection.type === "layered" && (
                                      <div
                                        className={`mt-4 ${
                                          isShowNFTDetail[index * 4 + i]
                                            ? "nft-card-details"
                                            : "gray-border"
                                        }`}
                                        style={{
                                          cursor:
                                            state.collection.type === "layered"
                                              ? "pointer"
                                              : "",
                                        }}
                                        onClick={() => {
                                          if (
                                            state.collection.type === "layered"
                                          ) {
                                            handleNFTDetails(index * 4 + i);
                                            getNFTMetadata(index * 4 + i);
                                          }
                                        }}
                                      >
                                        <div className=" d-flex justify-content-center align-items-center">
                                          <img
                                            src={`${
                                              process.env.REACT_APP_API_URL
                                            }/${
                                              collection.folderName.split(
                                                "/"
                                              )[2]
                                            }/assets/${index * 4 + i}.png`}
                                            alt=""
                                            className="card-image mb-3"
                                          />
                                        </div>
                                        <div className="">
                                          <div
                                            className="mb-2 deploy-card-title d-flex justify-content-center align-items-center"
                                            style={{ fontSize: "15px" }}
                                          >
                                            {state.collection.collection_name} #
                                            {index * 4 + i + 1}
                                          </div>
                                          <div
                                            className="deploy-card-status d-flex justify-content-center align-items-center"
                                            style={{ color: "#FFFFFF" }}
                                          >
                                            Details
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                );
                              })}
                          </div>

                          <div className="testing mb-3">
                            {nfts
                              .slice(index * 4, (index + 1) * 4)
                              .map((nftImage, i) => {
                                return (
                                  isShowNFTDetail[index * 4 + i] && (
                                    <div
                                      className="nftmetadataview ms-3 mb-3 p-3"
                                      style={{ width: "98%" }}
                                    >
                                      <Row className="mt-3">
                                        {nftDetails[
                                          index * 4 + i
                                        ]?.attributes?.map((a, i) => (
                                          <Col
                                            className="mb-3"
                                            xs={6}
                                            sm={6}
                                            md={3}
                                            key={i}
                                          >
                                            <div className="attribute-block">
                                              <span className="attribute-label">
                                                {a.trait_type}
                                              </span>
                                              <span className="attribute-value mb-2">
                                                {a.value}
                                              </span>
                                              <span className="attribute-trait">
                                                {`${getRarityValue(
                                                  a.trait_type,
                                                  a.value
                                                )} % have this trait`}
                                              </span>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  )
                                );
                              })}
                          </div>
                        </>
                      );
                    })}
                {state.collection.type === "video" &&
                  videoUrls.map((video, index) => {
                    return (
                      <Col md={3} className={`px-3`}>
                        <div>
                          <div className="justify-content-center align-items-center">
                            <video
                              controls
                              className="card-image mb-3"
                              width="220"
                              height="220"
                            >
                              <source src={video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <div
                            className="mb-2 deploy-card-title d-flex justify-content-center align-items-center"
                            style={{ fontSize: "15px" }}
                          >
                            {state.collection.nft_handle} #{index + 1}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </InfiniteScroll>
            {isLoading && <ContentSpinner />}
          </div>
        </div>
      </div>
      {isShowNFTDetailModal && (
        <Modal
          show={isShowNFTDetailModal}
          centered
          onHide={() => setIsShowNFTDetailsModal(false)}
          className="preview-nft-metadata-model"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center mx-auto">
              Nft Metadata
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {delete nftDetails.tempImg}
              <pre
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(nftDetails, null, 2),
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {isShowDepositeModal && (
        <Modal
          show={isShowDepositeModal}
          centered
          backdrop="static"
          onHide={() => {
            setIsShowDepositeModal(false);
            setIsDepositeLoading(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center mx-auto">Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Please Deposit the exact total amount of{" "}
              {Number(
                (state.collection?.no_of_nfts
                  ? state.collection.no_of_nfts
                  : formik.values.noOfNfts) *
                  0.0018 +
                  0.05 +
                  0.0018
              ).toFixed(4)}{" "}
              to the custodial wallet
              <OverlayTrigger
                key={"top"}
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{walletAddress}</Tooltip>}
                className="w-100"
              >
                <span
                  className="ms-2"
                  onClick={() => {
                    copyText(walletAddress);
                  }}
                >
                  {walletAddress.substring(0, 6) +
                    "..." +
                    walletAddress.substring(walletAddress.length - 4)}
                  .
                </span>
              </OverlayTrigger>{" "}
              belonging to you and your NFT Creation to publish your NFT
              Collection.
            </div>
            <div className="mb-3">
              This amount is purely used for publishing and adding your
              collection to the blockchain and if selected, to provide and use
              WL spots for your WL Quotas on your NFT Collection Mint Page.
            </div>
            <div className="mb-3">
              You are able to withdraw unused SOL anytime after your mint is
              finished from the custodial wallet related to your NFT Collection.
            </div>
            <div>
              If you are not proceed here, your collection will be stored as
              draft. Feel free to proceed anytime later.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btnGray"
              onClick={handleDeposit}
              disabled={isDepositeLoading}
            >
              {isDepositeLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-1"
                />
              )}
              Deposit
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {generatMsgModal && (
        <Modal
          show={generatMsgModal}
          centered
          backdrop="static"
          onHide={() => {
            setGeneratMsgModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 text-center">
              Don't close window, Deployment is an under process, it can take
              few minutes.
            </div>
          </Modal.Body>
        </Modal>
      )}
      {depositeModal && (
        <Modal
          show={depositeModal}
          centered
          backdrop="static"
          onHide={() => {
            setIsDepositeModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Don't close window, Deposit is under process, it can take few
              seconds.
            </div>
          </Modal.Body>
        </Modal>
      )}
      {redeployModal && (
        <Modal
          show={redeployModal}
          centered
          backdrop="static"
          onHide={() => {
            setRedployeModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header
            closeButton
            className="d-flex justify-content-end"
          ></Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              Sorry, something went wrong and your collection deployment was
              failed. Pls just try again. If the error persist pls give us a
              message on{" "}
              <a
                href={`https://discord.com/invite/4GgvDqt47M`}
                target="_blank"
                style={{ color: "#eddfb2" }}
              >
                Discord
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btnGray" onClick={checkBalance}>
              Redeploy
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {videoModal && (
        <Modal
          show={videoModal}
          centered
          backdrop="static"
          onHide={() => {
            setVideoModal(false);
          }}
          className="preview-nft-metadata-model"
        >
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">Please wait while we extract the videos!</div>
            {/* <div className="mb-3">
              NFT image generation is currently in progress and may take a few
              minutes. Thank you for your understanding.
            </div> */}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
