import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DynamicMintEditPage from "./Mint/DynamicMintEdit";
import MintEditPage from "./Mint/MintEditPage";
import MintedNFTS from "./Mint/MintedNFTs";
import MintPage from "./Mint/MintPage";
import NFTCreator from "./NFTCreator";

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={NFTCreator} />
          <Route exact path="/:nft_handle/mint" component={MintPage} />
          <Route exact path="/:nft_handle/edit/:token_id" component={MintEditPage} />
          <Route exact path="/dynamic/:nft_handle/edit/:token_id" component={DynamicMintEditPage} />
          <Route exact path="/minted-nfts" component={MintedNFTS} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
