import { useContext, useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import Confetti from "react-confetti";
import AxiosInstance from "../AxiosRequest";
import { DeviceContext } from "../Context/DeviceContext";
import { WalletContext } from "../Context/WalletContextProvider";
import { authContext } from "../Context/Web3AuthContext";
import collectionicon9 from "../assets/images/socialIcon/collection_twitter.svg";
import collectionicon10 from "../assets/images/socialIcon/collection_discord.svg";
import placholderImage from "../assets/images/placeholder1.png";
import websiteLogo from "../assets/images/socialIcon/collection_website.svg";
import { useHistory, useParams } from "react-router-dom";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import MyNFTToken from "../contract/MyNFTToken.json";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";

import { clusterSolanaApiUrl } from "../constants";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";
import { getCandyMachineState, mintOneToken } from "../methods/mint";
import getProvider from "../methods/getProvider";
import { SolanaContext } from "../Context/SolanaContextProvider";
const MintPage = () => {
  const [collectionDetails, setCollectionDetails] = useState();
  const { device } = useContext(DeviceContext);
  const [nftMinted, setNFTMinted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [collectionImage, setCollectionImage] = useState();
  const [isConfetti, setConfetti] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHight] = useState(window.innerHeight);
  const { address, provider } = useContext(authContext);
  const [tokenAmount, setTokenAmount] = useState();
  const [tokenAmountErrorMessage, setTokenAmountErrorMessage] = useState("");
  const [tokenId, setTokenId] = useState();
  const [tokenIdErrorMessage, setTokenIdErrorMessage] = useState("");
  const { publicKey } = useContext(SolanaContext);
  const params = useParams();
  const {
    getWalletConnectDropDown,
    getWalletButtonBasedSelectedChain,
    selectedChain,
    setActiveChain,
    connectWallet,
  } = useContext(WalletContext);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const [nftMintedPerUser, setNFTMintedPerUser] = useState();
  const [avilableToken, setAvilableToken] = useState(0);
  const [nftDetails, setNFTDetails] = useState();
  const connection = new anchor.web3.Connection(clusterSolanaApiUrl);
  const wallet = getProvider();
  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    };
  }, [wallet, publicKey]);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (nftMinted && collectionDetails?.erc === "ERC 1155") {
      getNFTDetailsByTokenId(Number(nftMinted) - 1);
    }
  }, [nftMinted, collectionDetails]);

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getDataBySymbolAndChain?nft_handle=${params.nft_handle}&chain=${selectedChain}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        if (response.data.data?.collection_image) {
          setCollectionImage(`${process.env.REACT_APP_API_URL}${response.data.data.collection_image}`);
        }
        setCollectionDetails(response.data.data);
        // setMintEdition(response.data.data.mint_edition);
        if (response.data.data?.minted_nfts) {
          setNFTMinted(response.data.data.minted_nfts);
        }
        if (response.data.data.chain === "Solana" && anchorWallet) {
          const cndy = await getCandyMachineState(
            anchorWallet,
            new anchor.web3.PublicKey(response.data.data.candyMachineId),
            connection
          );
          setNFTMinted(cndy.state.itemsRedeemed);
        }
      }
    }
  };

  const getNFTDetailsByTokenId = async (value) => {
    try {
      console.log("hello");
      const response = await AxiosInstance.post("/nfts/getNftDetails", {
        token_id: Number(value),
        nft_handle: collectionDetails.nft_handle,
        chain: selectedChain,
      });
      console.log(response);
      if (response.data.data?._id) {
        setNFTDetails(response.data.data);
        setAvilableToken(response.data.data.token_amount);
      } else {
        setAvilableToken(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const wait = (delay, ...args) =>
    new Promise((resolve) => setTimeout(resolve, delay, ...args));

  const checkActivity = async (tx) => {
    return wait(15000).then(async () => {
      const result = await connection.getSignatureStatus(tx, {
        searchTransactionHistory: true,
      });
      console.log(result);
      if (result.value?.confirmationStatus === "finalized") {
        console.log("txn successfull");
        return true;
      } else {
        console.log("txn failed");
        return false;
      }
    });
  };

  useEffect(() => {
    if (params?.nft_handle && (address || publicKey)) {
      getData();
    }
  }, [params, address, publicKey]);

  useEffect(() => {
    if (address && params?.nft_handle) {
      getNFTByWalletAddress();
    }
  }, [nftMinted, address, params]);

  const getNFTByWalletAddress = async () => {
    try {
      const response = await AxiosInstance.post("/nfts/nftsByWalletAddress", {
        address: address,
        nft_handle: params.nft_handle,
      });
      setNFTMintedPerUser(response.data.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const validateTokenAmount = async (value) => {
    setTokenAmount(value);
    let regExp = new RegExp("^\\d+$");
    let isValid = regExp.test(value);
    if (isValid) {
      if (value > collectionDetails.no_of_nft_amount) {
        setTokenAmountErrorMessage(
          "Value must be less then total no of nft amount"
        );
      } else if (Number(value) < 1) {
        setTokenAmount(value);
        setTokenAmountErrorMessage("Must be a greter than or equal to 1");
      } else if (
        Number(value) >
        collectionDetails?.no_of_nft_amount - avilableToken
      ) {
        setTokenAmountErrorMessage(
          `For ${tokenId} tokenid ${
            collectionDetails?.no_of_nft_amount - avilableToken
          } no of token avilable `
        );
      } else {
        setTokenAmountErrorMessage("");
      }
    } else {
      setTokenAmountErrorMessage("Must be a number");
    }
  };

  const validateTokenIdAmount = async (value) => {
    let regExp = new RegExp("^\\d+$");
    let isValid = regExp.test(value);
    if (isValid) {
      if (value > collectionDetails.no_of_nfts - 1) {
        setTokenIdErrorMessage("Value must be less then total no of nfts");
        setTokenId(value);
      } else if (Number(value) < 0) {
        setTokenId(value);
        setTokenIdErrorMessage("Must be a greter than or equal to 0");
      } else if (
        Number(value) === Number(nftMinted) - 1 &&
        collectionDetails.no_of_nft_amount === avilableToken
      ) {
        setTokenIdErrorMessage(
          `For ${value} token_id all the token amount are minted`
        );
        setTokenId(value);
      } else {
        setTokenIdErrorMessage("");
        setTokenId(value);
        await getNFTDetailsByTokenId(value);
      }
    } else {
      setTokenId(value);
      setTokenIdErrorMessage("Must be a number");
    }
  };

  const mintNFT = async () => {
    try {
      if (collectionDetails.chain === "Solana") {
        const balance =
          Number(await connection.getBalance(wallet.publicKey)) / 1e9;
        if (balance > collectionDetails.price_per_nft) {
          setIsLoading(true);
          const cndy = await getCandyMachineState(
            anchorWallet,
            new anchor.web3.PublicKey(collectionDetails.candyMachineId),
            connection
          );
          let hasSuccessed = false;
          setNFTMinted(cndy.state.itemsRedeemed);
          const mint = anchor.web3.Keypair.generate();
          const response = await mintOneToken(
            cndy,
            wallet.publicKey,
            mint,
            [],
            [],
            new anchor.web3.PublicKey(collectionDetails.collectionMint)
          );
          const resp = await checkActivity(response.mintTxId);
          if (resp) {
            hasSuccessed = true;
          } else {
            hasSuccessed = false;
          }
          if (hasSuccessed) {
            const cndy1 = await getCandyMachineState(
              anchorWallet,
              new anchor.web3.PublicKey(collectionDetails.candyMachineId),
              connection
            );
            await updateInCollection(mint);
            setNFTMinted(cndy1.state.itemsRedeemed);
            await getData();
            setIsLoading(false);
            setTimeout(() => {
              setConfetti(true);
            }, 300);
            toast.success("NFT Minted successfully.");
            setTimeout(() => {
              setConfetti(false);
            }, 15000);
          } else {
            setIsLoading(false);
            setConfetti(false);
            toast.error("something went wrong");
          }
        } else {
          toast.error(
            "You don't have sufficient balance in wallet to mint this NFT."
          );
        }
      } else {
        let isNetworkChange = false;
        if (window.ethereum.networkVersion !== collectionDetails?.network_id) {
          try {
            isNetworkChange = await connectWallet(
              collectionDetails.network_id ===
                process.env.REACT_APP_ETHEREUM_NETWORK_ID
                ? process.env.REACT_APP_ETHEREUM_CHAIN
                : process.env.REACT_APP_POLYGON_CHAIN,
              collectionDetails.network_id,
              setIsLoading
            );
          } catch (err) {
            console.log(err);
            setIsLoading(false);
            toast.error("Something went wrong");
          }
        } else {
          isNetworkChange = true;
        }
        if (isNetworkChange) {
          await getMintDetails();
        }
      }
    } catch (err) {
      setConfetti(false);
      setIsLoading(false);
      console.log(err);
    }
  };

  const getMintDetails = async () => {
    if (collectionDetails.erc === "ERC 721") {
      setIsLoading(true);
      const web3 = new Web3(window.ethereum);
      const nftContract = new web3.eth.Contract(
        Nft.abi,
        collectionDetails.contract_address
      );
      const tx = await nftContract.methods.mint(1).send({
        from: address,
        value: web3.utils.toWei(
          collectionDetails.price_per_nft.toString(),
          "ether"
        ),
      });
      let user, nftId, URI;
      await web3.eth.getTransactionReceipt(
        tx.transactionHash,
        (error, receipt) => {
          const eventAbi = Nft.abi.find((abi) => abi.name === "Mint");
          const mintEvent = receipt.logs.find(
            (log) => log.topics[0] === eventAbi.signature
          );
          const decodedData = web3.eth.abi.decodeLog(
            eventAbi.inputs,
            mintEvent.data,
            mintEvent.topics.slice(1)
          );
          user = decodedData.user;
          nftId = decodedData.tokenId;
          URI = decodedData.URI;
        }
      );
      await AxiosInstance.post("/nftcreator/updateStage", {
        _id: collectionDetails._id,
        contract_address: collectionDetails.contract_address,
        minted_nfts: Number(nftId) + 1,
        uri: collectionDetails.uri,
        stage: "Ready to Mint",
      });
      // await AxiosInstance.post("/users/mintednfts", {
      //   wallet_address: user,
      //   token_id: Number(nftId),
      //   nft_handle: collectionDetails.nft_handle,
      //   token_amount: tokenAmount,
      // });
      const metadata = await axios.get(
        `${process.env.REACT_APP_API_URL}/${
          collectionDetails.folderName.split("/")[2]
        }/assets/${Number(nftId)}`
      );
      await AxiosInstance.post("/nfts/create", {
        owner_publickey: user,
        token_id: Number(nftId),
        nft_handle: collectionDetails.nft_handle,
        attributes: metadata.data?.attributes,
        name: metadata.data.name,
        image_url: `${process.env.REACT_APP_API_URL}/${
          collectionDetails.folderName.split("/")[2]
        }/assets/${Number(nftId)}.png`,
        metadata_url: URI,
        type: collectionDetails.type,
        erc: collectionDetails.erc,
        isDynamic: collectionDetails.isDynamic,
        chain: collectionDetails.chain,
        token_amount: 0,
      });
      setNFTMinted(Number(nftId) + 1);
      setTimeout(() => {
        setConfetti(true);
      }, 300);
      toast.success("NFT Minted successfully.");
      setTimeout(() => {
        setConfetti(false);
      }, 15000);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const web3 = new Web3(window.ethereum);
      const nftContract = new web3.eth.Contract(
        MyNFTToken.abi,
        collectionDetails.contract_address
      );
      const tx = await nftContract.methods
        .mint(Number(tokenId), Number(tokenAmount))
        .send({
          from: address,
          value: web3.utils.toWei(
            (
              Number(tokenAmount) * Number(collectionDetails.price_per_nft)
            ).toString(),
            "ether"
          ),
        });
      let user, nftId, URI;
      await web3.eth.getTransactionReceipt(
        tx.transactionHash,
        (error, receipt) => {
          const eventAbi = MyNFTToken.abi.find((abi) => abi.name === "Mint");
          const mintEvent = receipt.logs.find(
            (log) => log.topics[0] === eventAbi.signature
          );
          const decodedData = web3.eth.abi.decodeLog(
            eventAbi.inputs,
            mintEvent.data,
            mintEvent.topics.slice(1)
          );
          user = decodedData.user;
          nftId = decodedData.tokenId;
          URI = decodedData.URI;
        }
      );
      await AxiosInstance.post("nftcreator/updateStage", {
        _id: collectionDetails._id,
        contract_address: collectionDetails.contract_address,
        minted_nfts: Number(nftId) + 1,
        uri: collectionDetails.uri,
        stage: "Ready to Mint",
      });
      // await AxiosInstance.post("/users/mintednfts", {
      //   wallet_address: user,
      //   token_id: Number(nftId),
      //   nft_handle: collectionDetails.nft_handle,
      // });
      const metadata = await axios.get(
        `${process.env.REACT_APP_API_URL}/${
          collectionDetails.folderName.split("/")[2]
        }/assets/${Number(nftId)}`
      );
      if (Number(nftMinted) - 1 === Number(tokenId)) {
        console.log("hello");
        await AxiosInstance.post("nfts/updateErc1155", {
          id: nftDetails._id,
          owner_publickey: user,
          token_amount: tokenAmount + avilableToken,
        });
      } else {
        console.log("else");
        await AxiosInstance.post("nfts/create", {
          owner_publickey: user,
          token_id: Number(nftId),
          nft_handle: collectionDetails.nft_handle,
          attributes: metadata.data?.attributes,
          name: metadata.data.name,
          image_url: `${process.env.REACT_APP_API_URL}/${
            collectionDetails.folderName.split("/")[2]
          }/assets/${Number(nftId)}.png`,
          metadata_url: URI,
          type: collectionDetails.type,
          chain: collectionDetails.chain,
          token_amount: tokenAmount,
          erc: collectionDetails.erc,
        });
      }
      setTokenId("");
      setTokenAmount("");
      setNFTMinted(Number(nftId) + 1);
      setTimeout(() => {
        setConfetti(true);
      }, 300);
      toast.success("NFT Minted successfully.");
      setTimeout(() => {
        setConfetti(false);
      }, 15000);
      setIsLoading(false);
    }
  };

  const updateInCollection = async (mint) => {
    try {
      await AxiosInstance.post(`/nftcreator/updateInCollection`, {
        mint: mint.publicKey.toString(mint),
        candyMachineId: collectionDetails.candyMachineId,
        nft_handle: collectionDetails.nft_handle,
        collection_name: collectionDetails.collection_name,
        logo_image: collectionDetails?.collection_image
          ? collectionDetails?.collection_image
          : `${process.env.REACT_APP_API_URL}/${
              collectionDetails?.folderName.split("/")[2]
            }/assets/collection.png`,
        banner_image: collectionDetails?.collection_image
          ? collectionDetails?.collection_image
          : `${process.env.REACT_APP_API_URL}/${
              collectionDetails?.folderName.split("/")[2]
            }/assets/collection.png`,
        website: collectionDetails.website,
        discord: collectionDetails.discord_url,
        twitter: collectionDetails.twitter_url,
        wallet: wallet.publicKey.toString(),
        type: collectionDetails.type,
      });
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="nft-creator">
      <ToastContainer />
      {isConfetti && <Confetti width={width} height={height} />}
      {selectedChain === "Solana" && !publicKey && (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      )}
      {selectedChain !== "Solana" && !address && (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      )}
      {((selectedChain === "Solana" && publicKey) ||
        (selectedChain !== "Solana" && address)) && (
        <div
          style={{
            maxWidth: "14000px",
            paddingBottom: "10px",
            paddingTop: "120px",
            minHeight: "100vh",
          }}
        >
          <div className="container mt-5">
            <div className="collection-details">
              {device === "mobile" ? (
                <div className="nft-name text-center mb-2">
                  {collectionDetails && collectionDetails.collection_name}
                </div>
              ) : null}
              <Row>
                <Col
                  xl={4}
                  lg={4}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <img
                    className="mint-nftimage"
                    src={collectionImage ? collectionImage : placholderImage}
                    alt=""
                  />
                </Col>
                <Col xl={7} lg={7} sm={12} className="ms-4">
                  <div className="mb-4">
                    <div className="d-flex collection-description-details">
                      <div>
                        {device !== "mobile" && (
                          <div className="nft-name mb-1">
                            {collectionDetails &&
                              collectionDetails.collection_name}
                          </div>
                        )}
                        <div className="label-name">
                          {collectionDetails && collectionDetails.description}
                        </div>
                        <div className="d-flex flex-wrap mt-2 mb-2">
                          {collectionDetails &&
                            collectionDetails.twitter_url && (
                              <a
                                href={`https://twitter.com/${collectionDetails.twitter_url}`}
                                rel="noreferrer"
                                target={"_blank"}
                                style={{ textDecoration: "none" }}
                              >
                                <span
                                  className={`mb-2 me-3 align-items-center image1`}
                                >
                                  <img
                                    src={collectionicon9}
                                    alt="twitter"
                                    className=""
                                  />
                                </span>
                              </a>
                            )}
                          {collectionDetails &&
                            collectionDetails.discord_url && (
                              <a
                                href={`${collectionDetails.discord_url}`}
                                target={"_blank"}
                              >
                                <span className="image1 mb-2 me-3">
                                  <img
                                    src={collectionicon10}
                                    alt="discord"
                                    className=""
                                  />
                                </span>
                              </a>
                            )}
                          {collectionDetails && collectionDetails.website && (
                            <a
                              href={collectionDetails.website}
                              target={"_blank"}
                            >
                              <span className="image1 mb-2 me-3">
                                <img
                                  src={websiteLogo}
                                  alt="website"
                                  // className=""
                                />
                              </span>
                            </a>
                          )}
                        </div>
                        {collectionDetails && collectionDetails.whitepaper && (
                          <div className="">
                            <a
                              className="textDetails"
                              href={`${collectionDetails.whitepaper}`}
                              target={"_blank"}
                            >
                              Whitepaper
                            </a>
                          </div>
                        )}
                      </div>
                      {device !== "mobile" && (
                        <div
                          className="hr mx-4"
                          style={{ maxHeight: "180px" }}
                        ></div>
                      )}
                      <div className="total-items">
                        <div className="label-name mb-1 mt-2">Total Items</div>
                        <div className="deploy-card-title">
                          {collectionDetails && collectionDetails.no_of_nfts}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-5 total-minted">
                    {collectionDetails?.erc === "ERC 721" && (
                      <>
                        <div className="mb-2 label-name">Total Minted</div>
                        <div className="d-flex justify-content-between mb-1">
                          <div
                            className="deploy-card-status"
                            style={{ color: "#FFFFFF" }}
                          >
                            <>
                              {Number(
                                (nftMinted * 100) /
                                  collectionDetails?.no_of_nfts
                              ).toFixed(2)}
                              %{" "}
                            </>
                          </div>
                          <div
                            className="deploy-card-status"
                            style={{ color: "#FFFFFF" }}
                          >
                            <>
                              {nftMinted} /{" "}
                              {collectionDetails &&
                                collectionDetails.no_of_nfts}
                            </>
                          </div>
                        </div>
                        <div className="progress mb-3">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${
                                (nftMinted * 100) /
                                collectionDetails?.no_of_nfts
                              }%`,
                            }}
                          ></div>
                        </div>
                      </>
                    )}
                    <div className="d-flex justify-content-between">
                      {collectionDetails?.erc === "ERC 721" ? (
                        <div className="d-flex" style={{ flexFlow: "column" }}>
                          <div className="label-name ">Price</div>
                          <div className="header-title ">
                            {collectionDetails ? (
                              String(collectionDetails.price_per_nft).includes(
                                "-"
                              ) ? (
                                parseFloat(
                                  collectionDetails.price_per_nft
                                ).toFixed(
                                  String(collectionDetails.price_per_nft).split(
                                    "-"
                                  )[1]
                                )
                              ) : collectionDetails.erc === "ERC 721" ? (
                                parseFloat(collectionDetails.price_per_nft)
                              ) : (
                                parseFloat(
                                  collectionDetails.price_per_nft *
                                    (tokenAmount
                                      ? Number(tokenAmount)
                                      : Number(1))
                                ).toFixed(3)
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between mb-1">
                          <div className="my-auto">
                            <div className="label-name d-flex justify-content-center align-items-center">
                              Price
                            </div>
                            <div className="header-title d-flex justify-content-center align-items-center">
                              {collectionDetails ? (
                                String(
                                  collectionDetails.price_per_nft
                                ).includes("-") ? (
                                  parseFloat(
                                    collectionDetails.price_per_nft
                                  ).toFixed(
                                    String(
                                      collectionDetails.price_per_nft
                                    ).split("-")[1]
                                  )
                                ) : collectionDetails.erc === "ERC 721" ? (
                                  parseFloat(collectionDetails.price_per_nft)
                                ) : (
                                  parseFloat(
                                    collectionDetails.price_per_nft *
                                      (tokenAmount
                                        ? Number(tokenAmount)
                                        : Number(1))
                                  ).toFixed(3)
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <div
                          className="d-flex justify-content-end align-items-center mb-1"
                          style={{ flexFlow: "column" }}
                        >
                          {collectionDetails &&
                            collectionDetails.erc &&
                            collectionDetails.erc === "ERC 1155" && (
                              <div className="d-flex mb-3">
                                <div>
                                  <Form.Label
                                    className="form-label"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Token Id
                                  </Form.Label>
                                  <Form.Control
                                    style={{ width: "140px", height: "44px" }}
                                    className="form-input-text"
                                    type="text"
                                    value={tokenId}
                                    onChange={(e) =>
                                      validateTokenIdAmount(e.target.value)
                                    }
                                    disabled={isLoading}
                                  />
                                </div>
                                <div>
                                  <Form.Label
                                    className="form-label"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Token Amount
                                  </Form.Label>
                                  <Form.Control
                                    style={{ width: "140px", height: "44px" }}
                                    className="form-input-text ms-2"
                                    type="text"
                                    value={tokenAmount}
                                    onChange={(e) =>
                                      validateTokenAmount(e.target.value)
                                    }
                                    disabled={isLoading}
                                  />
                                </div>
                              </div>
                            )}
                          <div style={{ minWidth: "150px" }}>
                            {collectionDetails &&
                            collectionDetails.no_of_nfts === nftMinted &&
                            collectionDetails.erc === "ERC 721" ? (
                              <span className="label-name">
                                All NFT's are minted
                              </span>
                            ) : collectionDetails &&
                              collectionDetails.no_of_nfts_per_user ===
                                nftMintedPerUser ? (
                              <span className="label-name">
                                You have reached your limit
                              </span>
                            ) : (
                              <button
                                className="btnGray"
                                onClick={() => mintNFT()}
                                disabled={
                                  isLoading ||
                                  tokenAmountErrorMessage.length > 0 ||
                                  tokenIdErrorMessage.length > 0
                                }
                              >
                                {isLoading && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                  />
                                )}
                                Mint
                              </button>
                            )}
                            {nftMinted > 0 && (
                              <button
                                className="btnGray ms-4"
                                onClick={() => history.push("/minted-nfts")}
                                disabled={isLoading}
                              >
                                Explore Collection
                              </button>
                            )}
                          </div>
                        </div>
                        {tokenIdErrorMessage.length > 0 && (
                          <span className="text-danger">
                            {tokenIdErrorMessage}
                          </span>
                        )}
                        {tokenAmountErrorMessage.length > 0 && (
                          <span className="text-danger">
                            {tokenAmountErrorMessage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end"></div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="p-3">
              {collectionDetails && collectionDetails.about && (
                <>
                  <div className="mb-5 about-details">About</div>
                  <div className="mb-4 label-name">
                    {collectionDetails.about
                      .split("\n")
                      .map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MintPage;
