import React from "react";

const VidAudDocUpload = ({
  formik,
}) => {
  const handleFileUpload = async (e) => {
    const file = e?.target?.files[0];
    if (file) {
      try {
        formik.setFieldValue("zipfile", file);
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <div>
      <div className="mt-4">
        <div className="col-6">
          <div className="nft-img-header">Upload Files</div>
          <input
            className="mt-2"
            accept=".zip,.rar,.7zip"
            type="file"
            name="zipfile"
            // value={formik.values.zipfile}
            onChange={handleFileUpload}
          />
        </div>
        <div className="mt-2" style={{color: "#ff0000"}}>Note: Please ensure that the number of files in the uploaded zip folder matches the value you entered in the 'No. of NFTs' field. </div>
      </div>
    </div>
  );
};

export default VidAudDocUpload;
