import {toast, ToastContainer} from "react-toastify";

import { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import AxiosInstance from "../AxiosRequest";
import { WalletContext } from "../Context/WalletContextProvider";

const NFTDetails = ({ formik, state, setState, publicKey }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedChain, setActiveChain } = useContext(WalletContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [erc, setErc] = useState("ERC 721");
  const [noOfNFTAmount, setNoOfNFTAmount] = useState();
  const [noOfNFTAmountErrorMessage, setNoOfNFTAmountErrorMessage] =
    useState("");
  const [nftType, setNFTType] = useState("Static");

  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setState({
          ...state,
          collection: response.data.data,
        });
        formik.setFieldValue("projectName", response.data.data.collection_name);
        formik.setFieldValue("noOfNfts", response.data.data.no_of_nfts);
        formik.setFieldValue("price", response.data.data.price_per_nft);
        formik.setFieldValue(
          "creator_royalty",
          response.data.data.creator_royalty
        );
        formik.setFieldValue("nftSymbol", response.data.data.nft_handle);
        formik.setFieldValue(
          "noOfNftsPerUser",
          response.data.data.no_of_nfts_per_user
        );
        setErc(response.data.data.erc);
        setNoOfNFTAmount(response.data.data.no_of_nft_amount);
      }
    }
  };

  useEffect(() => {
    if (state.collection?._id) {
      getData();
    }
  }, [state.collection?._id, state.currentStep]);

  const validateNumber = (value) => {
    let regExp = new RegExp("^\\d+$");
    let isValid = regExp.test(value);
    if (isValid) {
      setNoOfNFTAmountErrorMessage("");
      setNoOfNFTAmount(value);
    } else {
      setNoOfNFTAmount(value);
      setNoOfNFTAmountErrorMessage("Must be a Number");
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        state.collection?.no_of_nfts_per_user
          ? Number(state.collection?.no_of_nfts_per_user) >
              Number(state.collection?.no_of_nfts) ||
            Number(state.collection?.no_of_nfts_per_user) >
              Number(formik.values.noOfNfts) ||
            Number(formik.values?.noOfNftsPerUser) >
              Number(state.collection?.no_of_nfts)
          : Number(formik.values?.noOfNftsPerUser) >
            Number(formik.values.noOfNfts)
      ) {
        setErrorMessage("Must be less then or equal to Total no of nfts");
      } else if (erc === "ERC 1155" && !noOfNFTAmount) {
        setNoOfNFTAmountErrorMessage("This field is require");
      } else {
        setErrorMessage("");
        setNoOfNFTAmountErrorMessage("");
        setIsLoading(true);
        const response = await AxiosInstance.post("/nftcreator/update", {
          _id: state.collection._id ? state.collection._id : "",
          creator: publicKey
            ? publicKey
            : state.collection?.creator
            ? state.collection.creator
            : formik.values.creator,
          creator_email: state.collection?.creator_email
            ? state.collection.creator_email
            : formik.values.creator_email,
          collection_name: state.collection?.collection_name
            ? state.collection.collection_name
            : formik.values.projectName,
          price_per_nft: state.collection?.price_per_nft
            ? state.collection.price_per_nft
            : formik.values.price,
          no_of_nfts: formik.values.noOfNfts
            ? formik.values.noOfNfts
            : state.collection.no_of_nfts,
          nft_handle: state.collection?.nft_handle
            ? state.collection.nft_handle.toLowerCase()
            : formik.values.nftSymbol.toLowerCase(),
          layers: state.collection?.layers
            ? state.collection.layers
            : formik.values.layers,
          creator_royalty: state.collection?.creator_royalty
            ? state.collection.creator_royalty
            : formik.values.creator_royalty,
          no_of_nfts_per_user: formik.values.noOfNftsPerUser
            ? formik.values.noOfNftsPerUser
            : state.collection.no_of_nfts_per_user,
          type: state.collection?.type
            ? state.collection.type
            : formik.values.type,
          stage: "NFTDetails",
          chain: selectedChain,
          erc: erc,
          no_of_nft_amount: Number(noOfNFTAmount),
          network_id: setActiveChain,
          isDynamic: nftType === "Static" ? false : true,
        });
        if (response.status === 200) {
          if (response.data && response.data.data?._id) {
            await AxiosInstance.post("/nftcreator/updateStage", {
              _id: response.data.data._id,
              stage: "Collection Details",
            });
            setIsLoading(false);
            const folder = response.data.data.folderName;
            const folderName = folder?.split("/");
            setState({
              ...state,
              collection: response.data.data,
              folderName: folderName?.[2],
              currentStep: 3,
            });
          } else {
            toast.error(response.data.message);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <>
      <div className="row">
      <ToastContainer />
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="grayBox">
            <Row>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">Create NFT For</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Your wallet used to mint NFTs for users (If you're
                          using the wrong wallet to mint NFTs for users, please
                          feel free to change your wallet and reconnect here.)
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>

                <Form.Control
                  type="text"
                  id="chooseCreator"
                  className="form-input-text mt-2 mb-2"
                  placeholder="Choose a Creator"
                  readOnly
                  // onChange={(e) => {
                  //   formik.handleChange(e);
                  //   setState({
                  //     ...state,
                  //     collection: {
                  //       ...state.collection,
                  //       creator: e.target.value,
                  //     },
                  //   });
                  // }}
                  value={
                    state.collection?.creator
                      ? state.collection.creator
                      : publicKey
                      ? publicKey
                      : null
                  }
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  The user who will own the collection.
                </Form.Text>
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label mb-3">
                  Creator Email
                </Form.Label>
                <Form.Control
                  type="text"
                  id="creator_email"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        creator_email: e.target.value,
                      },
                    });
                  }}
                  value={
                    state.collection?.creator_email
                      ? state.collection.creator_email
                      : formik.values.creator_email
                  }
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter Email"
                />
                {formik.errors.creator_email && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.creator_email}
                  </Form.Text>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">NFT Collection Name</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Collection name (e.g. "Degenerative Apes")
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="projectName"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        collection_name: e.target.value,
                      },
                    });
                  }}
                  value={
                    state.collection?.collection_name
                      ? state.collection.collection_name
                      : formik.values.projectName
                  }
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter Name"
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  This name will be showed on the Mint page as title of the NFT
                  Collection
                </Form.Text>
                {formik.errors.projectName && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.projectName}
                  </Form.Text>
                )}
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">Price per NFT</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Initial item price for each NFT
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="price"
                  value={
                    state.collection?.price_per_nft
                      ? state.collection.price_per_nft
                      : formik.values.price
                  }
                  className="form-input-text mt-2 mb-2"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        price_per_nft: e.target.value,
                      },
                    });
                  }}
                  placeholder="Enter Price"
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  This will be the start price / mint price of your collection,
                  the price a user have to pay to get a NFT from your collection
                </Form.Text>
                {formik.errors.price && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.price}
                  </Form.Text>
                )}
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">No. of NFTs</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Maximum supply of NFTs to be created.
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="noOfNfts"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        no_of_nfts: e.target.value,
                      },
                    });
                  }}
                  value={
                    state.collection?.no_of_nfts
                      ? state.collection.no_of_nfts
                      : formik.values.noOfNfts
                  }
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter Number"
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  Keep in mind, larger Collection will take more time to
                  generate and will cost more to upload to the blockchain.
                </Form.Text>
                {formik.errors.noOfNfts && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.noOfNfts}
                  </Form.Text>
                )}
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">NFT Symbol</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          NFT symbol, which is the short name for your
                          collection. Each Item will start with that name
                          followed by the # and Item number example Degenerative
                          Apes #121.
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="nftSymbol"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        nft_handle: e.target.value,
                      },
                    });
                  }}
                  value={
                    state.collection?.nft_handle
                      ? state.collection.nft_handle
                      : formik.values.nftSymbol
                  }
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter Symbol"
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  This will be most used name of your collection, as shorter as
                  better
                </Form.Text>
                {formik.errors.nftSymbol && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.nftSymbol}
                  </Form.Text>
                )}
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex">
                    <span className="me-2">Artist Royalty</span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Artist royalties are payments made to artists for the
                          use of their work. These payments are typically a
                          percentage of revenue generated from the use of their
                          work, and the percentage varies based on the specific
                          agreement between the artist and the entity using
                          their work.
                        </Tooltip>
                      }
                    >
                      <div className="mb-2">
                        <svg
                          id="Group_2600"
                          data-name="Group 2600"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            id="Path_3248"
                            data-name="Path 3248"
                            d="M0,0H24V24H0Z"
                            fill="none"
                          />
                          <circle
                            id="Ellipse_450"
                            data-name="Ellipse 450"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(3 3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <line
                            id="Line_141"
                            data-name="Line 141"
                            x2="0.01"
                            transform="translate(12 8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3249"
                            data-name="Path 3249"
                            d="M11,12h1v4h1"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="creator_royalty"
                  placeholder="Enter Artist Royalty"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        creator_royalty: e.target.value,
                      },
                    });
                  }}
                  value={
                    state.collection?.creator_royalty
                      ? state.collection?.creator_royalty
                      : formik.values.creator_royalty
                  }
                  className="form-input-text mt-2 mb-2"
                />
                <Form.Text className="ms-2 mb-2 text-details d-flex">
                  Define your royalties - can be 0.00% - 100% usually its 0.5 -
                  8%
                </Form.Text>
                {formik.errors.creator_royalty && (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.creator_royalty}
                  </Form.Text>
                )}
              </Col>
              <Col md={12} lg={6} className="mb-5">
                <Form.Label className="form-label">
                  <div className="d-flex mb-2">
                    <span className="me-2">No. of NFTs Per User</span>
                  </div>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="noOfNftsPerUser"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setState({
                      ...state,
                      collection: {
                        ...state.collection,
                        no_of_nfts_per_user: e.target.value,
                      },
                    });
                    setErrorMessage("");
                  }}
                  value={
                    state.collection?.no_of_nfts_per_user
                      ? state.collection.no_of_nfts_per_user
                      : formik.values.noOfNftsPerUser
                  }
                  className="form-input-text mt-2 mb-2"
                  placeholder="Enter Number"
                />
                {formik.errors.noOfNftsPerUser ? (
                  <Form.Text className="text-danger ms-2">
                    {formik.errors.noOfNftsPerUser}
                  </Form.Text>
                ) : (
                  errorMessage.length > 0 && (
                    <Form.Text className="text-danger ms-2">
                      {errorMessage}
                    </Form.Text>
                  )
                )}
              </Col>
              {selectedChain !== "Solana" && (
                <Row>
                  <Col md={12} lg={6} className="mb-5">
                    <Form.Label className="form-label">Select Type</Form.Label>
                    <Form.Control
                      as="select"
                      className="form-input-text mt-2 mb-2"
                      placeholder="Please select chain type"
                      onChange={(e) => {
                        setErc(e.target.value);
                        setNoOfNFTAmountErrorMessage("");
                        setNoOfNFTAmount();
                        setNFTType("Static");
                      }}
                      required
                      value={erc}
                    >
                      <option value="ERC 721">ERC 721</option>
                      <option value="ERC 1155">ERC 1155</option>
                    </Form.Control>
                  </Col>
                  {erc === "ERC 1155" ? (
                    <Col md={12} lg={6} className="mb-5">
                      <Form.Label className="form-label">
                        No. Of NFT Amount
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-input-text mt-2 mb-2"
                        placeholder="Please add no of nft amount"
                        onChange={(e) => {
                          validateNumber(e.target.value);
                        }}
                        required
                        value={noOfNFTAmount}
                      />
                      {noOfNFTAmountErrorMessage.length > 0 && (
                        <Form.Text className="text-danger ms-2">
                          {noOfNFTAmountErrorMessage}
                        </Form.Text>
                      )}
                    </Col>
                  ) : (
                    <Col md={12} lg={6} className="mb-5">
                      <Form.Label className="form-label">
                        Select NFT Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="form-input-text mt-2 mb-2"
                        placeholder="Please select chain type"
                        onChange={(e) => {
                          setNFTType(e.target.value);
                        }}
                        required
                        value={nftType}
                      >
                        <option value="Static">Static</option>
                        <option value="Dynamic">Dynamic</option>
                      </Form.Control>
                    </Col>
                  )}
                </Row>
              ) 
              // : (
              //   <Row>
              //     <Col md={12} lg={6} className="mb-5">
              //       <Form.Label className="form-label">
              //         Select NFT Type
              //       </Form.Label>
              //       <Form.Control
              //         as="select"
              //         className="form-input-text mt-2 mb-2"
              //         placeholder="Please select chain type"
              //         onChange={(e) => {
              //           setNFTType(e.target.value);
              //           // setNoOfNFTAmountErrorMessage("");
              //           // setNoOfNFTAmount();
              //         }}
              //         required
              //         value={nftType}
              //       >
              //         <option value="Static">Static</option>
              //         <option value="Dynamic">Dynamic</option>
              //       </Form.Control>
              //     </Col>
              //   </Row>
              // )
              }
            </Row>
            <Row className="my-3 mb-5">
              <Col md={12} className="d-flex justify-content-end flex-wrap">
                <button
                  type="button"
                  className="btnGray"
                  onClick={handleSubmit}
                  style={{ width: "210px", padding: "0" }}
                  disabled={
                    !formik.values.projectName ||
                    !formik.values.price ||
                    !formik.values.noOfNfts ||
                    !formik.values.nftSymbol ||
                    !formik.values.creator_royalty ||
                    formik.errors.projectName ||
                    formik.errors.price ||
                    formik.errors.noOfNfts ||
                    formik.errors.nftSymbol ||
                    formik.errors.creator_royalty ||
                    formik.errors.creator_email ||
                    !formik.values.noOfNftsPerUser ||
                    formik.errors.noOfNftsPerUser ||
                    isLoading ||
                    errorMessage.length > 0 ||
                    noOfNFTAmountErrorMessage.length > 0
                  }
                >
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-1"
                    />
                  )}
                  Continue
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTDetails;
