import { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import AxiosInstance from "../AxiosRequest";
import { authContext } from "../Context/Web3AuthContext";
import icCamera from "../assets/images/Camera.svg";
import axios from "axios";
import { dataURLtoFile, jsontoFile } from "../NFTCreator/CommonMethode";
import { cloneDeep } from "lodash";
import {
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET,
  REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
  REACT_APP_PINATA_PIN_JSON_TO_IPFS_URL,
} from "../constants";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import {toast, ToastContainer} from "react-toastify";

import { DeviceContext } from "../Context/DeviceContext";
import placeHolder from "../assets/images/placeholder1.png";
import { WalletContext } from "../Context/WalletContextProvider";

const headers = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "multipart/form-data",
};

const DynamicMintEditPage = ({ dynamicNftImgURL, setDynamicNFTImgURL }) => {
  const [collectionDetails, setCollectionDetails] = useState();
  const { address } = useContext(authContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [layerIndex, setLayerIndex] = useState(0);
  const [nftcreator, setNFTCreator] = useState();
  const { device } = useContext(DeviceContext);
  const history = useHistory();
  const {
    getWalletConnectDropDown,
    getWalletButtonBasedSelectedChain,
    connectWallet,
  } = useContext(WalletContext);
  const dynamicNftRef = useRef(null);
  // const [dynamicNftImgURL, setDynamicNFTImgURL] = useState();
  const [attributes, setAttributes] = useState([
    {
      trait_type: "",
      value: "",
    },
  ]);
  const [traitErrorMessage, setTraitErrorMessage] = useState("");
  const [traitValueErrorMessage, setTraitValueErrorMessage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState([true]);
  const [lastIndex, setLastIndex] = useState(0);

  useEffect(() => {
    if (params?.nft_handle && params.token_id) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    if (params?.nft_handle) {
      getNFTCreatorData();
    }
  }, [params]);

  const getNFTCreatorData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getDataBySymbol?nft_handle=${params.nft_handle}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setNFTCreator(response.data.data);
      }
    }
  };

  const getData = async () => {
    const response = await AxiosInstance.post(
      "/nfts/getNFTByTokenIdAndSymbol",
      {
        token_id: params.token_id,
        nft_handle: params.nft_handle,
      }
    );
    if (response.status === 200) {
      setCollectionDetails(response.data.data);
      setAttributes(response.data.data.attributes);
      setLastIndex(response.data.data.attributes.length - 1);
      handleNFTDetails(
        response.data.data.attributes,
        response.data.data.attributes.length
      );
      // setDynamicNFTImgURL(response.data.data.image_url);
    }
  };

  const handleDynamicNFTImage = (e) => {
    if (e.target.files[0]) {
      setDynamicNFTImgURL(URL.createObjectURL(e.target.files[0]));
      dynamicNftRef.current.value = "";
    }
  };

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const handleSubmit = async () => {
    try {
      let isNetworkChange = false;
      console.log(
        window.ethereum.networkVersion,
        collectionDetails?.network_id
      );
      if (window.ethereum.networkVersion !== nftcreator?.network_id) {
        try {
          isNetworkChange = await connectWallet(
            nftcreator.network_id === process.env.REACT_APP_ETHEREUM_NETWORK_ID
              ? process.env.REACT_APP_ETHEREUM_CHAIN
              : process.env.REACT_APP_POLYGON_CHAIN,
            nftcreator.network_id,
            setIsLoading
          );
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          toast.error("Something went wrong");
        }
      } else {
        isNetworkChange = true;
      }
      if (isNetworkChange) {
        const data = await toDataURL(dynamicNftImgURL);
        setIsLoading(true);
        const iFormData = new FormData();
        iFormData.append(
          "file",
          dataURLtoFile(data, `${collectionDetails.token_id}.png`),
          `${nftcreator.collection_name}_Assets/${collectionDetails.token_id}.png`
        );

        const iResponse = await axios({
          method: "post",
          url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
          data: iFormData,
          headers,
        });

        const jFormData = new FormData();

        if (iResponse.data) {
          const nftMetadata = {
            name: collectionDetails.name,
            symbol: collectionDetails.nft_handle,
            description: "",
            seller_fee_basis_points: Number(nftcreator.creator_royalty) * 100,
            image: `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`,
            external_url: "",
            edition: collectionDetails.token_id,
            attributes: attributes,
          };
          nftMetadata.image = `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`;

          jFormData.append(
            "file",
            jsontoFile(nftMetadata, `${collectionDetails.token_id}.json`),
            `${nftcreator.collection_name}_Metadata/${collectionDetails.token_id}.json`
          );

          const jResponse = await axios({
            method: "post",
            url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
            data: jFormData,
            headers,
          });
          // const metadata = await axios.get(
          //   `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/${collectionDetails.token_id}.json`
          // );

          const web3 = new Web3(window.ethereum);
          const nftContract = new web3.eth.Contract(
            Nft.abi,
            nftcreator.contract_address
          );
          const tx = await nftContract.methods
            .SetTokenURI(
              collectionDetails.token_id,
              `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
            )
            .send({
              from: address,
            });
          console.log(tx);
          if (tx) {
            await AxiosInstance.post("/nfts/update", {
              id: collectionDetails._id,
              metadata_url: `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`,
              attributes: attributes,
              image_url: `https://gateway.pinata.cloud/ipfs/${
                nftMetadata.image.split("//")[1]
              }`,
              is_image_change: true,
            });
            toast.success("NFT Updated Successfully");
            history.push("/minted-nfts");
          }
          setIsLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleError = (e) => {
    e.target.src = placeHolder;
  };

  const handleTraitNameChange = (value, index) => {
    const array = attributes;
    setLayerIndex(index);
    if (!value) {
      setTraitErrorMessage("Please enter trait name");
      array[index].trait_type = "";
    } else {
      setTraitErrorMessage("");
      for (let i = 0; i < array.length - 1; i++) {
        if (value === array[i].trait_type) {
          setTraitErrorMessage("Please add different trait name");
          array[index].trait_type = value.toLowerCase();
          break;
        } else if (array[i].trait_type === "") {
          setTraitErrorMessage("Please enter trait name");
          break;
        } else {
          setTraitErrorMessage("");
        }
      }
      for (let i = 0; i < array.length; i++) {
        if (i === index) {
          array[i].trait_type = value.toLowerCase();
        } else {
          array[i].trait_type = array[i].trait_type;
        }
      }
    }
    setAttributes([...array]);
  };

  const handleTraitValueChange = (value, index) => {
    setLayerIndex(index);
    const array = attributes;
    if (!value) {
      setTraitValueErrorMessage("Please enter trait value");
      array[index].value = "";
    } else {
      setTraitValueErrorMessage("");
      for (let i = 0; i < array.length - 1; i++) {
        if (array[i].value === "") {
          setTraitValueErrorMessage("Please enter trait value");
          break;
        } else {
          setTraitValueErrorMessage("");
        }
      }
    }
    for (let i = 0; i < array.length; i++) {
      if (i === index) {
        array[i].value = value.toLowerCase();
      } else {
        array[i].value = array[i].value;
      }
    }
    setAttributes([...array]);
  };

  const addAttributes = (index) => {
    let array = attributes;
    array.push({
      trait_type: "",
      value: "",
    });
    setAttributes([...array]);
    setLastIndex(index + 1);
    handleNFTDetails(array, index + 1);
  };

  const removeAttributes = (index) => {
    attributes.splice(index, 1);
    selectedIndex.splice(index, 1);
    if (index === attributes.length) {
      setLastIndex(index - 1);
    } else {
      setLastIndex(attributes.length - 1);
    }
    setTraitErrorMessage("");
    setTraitValueErrorMessage("");
    handleNFTDetails(attributes, index);
  };

  const handleNFTDetails = (newAttributes, index) => {
    let array = [];
    if (newAttributes.length === 1) {
      setLayerIndex(0);
      array[0] = true;
    } else {
      setLayerIndex(index);
      for (let i = 0; i < newAttributes.length; i++) {
        if (newAttributes[i].trait_type === "") {
          array[i] = true;
        } else {
          array[i] = false;
        }
      }
      if (index === newAttributes.length) {
        array[index - 1] = true;
        setLayerIndex(index - 1);
      }
    }
    setSelectedIndex([...array]);
  };

  return (
    <div className="nft-creator">
      <ToastContainer />
      {!address ? (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      ) : (
        <>
          <div
            style={{
              maxWidth: "14000px",
              paddingBottom: "10px",
              paddingTop: "120px",
              minHeight: "100vh",
            }}
          >
            <div className="container mt-5">
              <div
                className="collection-details mb-5"
                style={{ minHeight: "200px" }}
              >
                <Row>
                  <Col xl={3} lg={3} sm={12} className="d-flex">
                    <button
                      className="file-button d-flex justify-content-end align-items-end"
                      type="button"
                      style={{
                        backgroundImage: `url(${
                          dynamicNftImgURL
                            ? dynamicNftImgURL
                            : collectionDetails?.image_url
                            ? collectionDetails.image_url
                            : placeHolder
                        })`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "280px",
                        height: "280px",
                        borderRadius: "35px",
                        padding: "30px",
                      }}
                      onClick={() => dynamicNftRef.current.click()}
                    >
                      <img src={icCamera} alt="..." className="plus-icon" />
                    </button>

                    <input
                      type={"file"}
                      ref={dynamicNftRef}
                      accept="image/*"
                      onChange={handleDynamicNFTImage}
                      className="hidden-file"
                    />
                  </Col>
                  <Col xl={7} lg={7} sm={12} className="ms-4">
                    <div className="mb-4">
                      <div className="d-flex collection-description-details">
                        <div>
                          {device !== "mobile" && (
                            <div className="nft-name mb-1">
                              {collectionDetails && collectionDetails.name}
                            </div>
                          )}
                          <div className="label-name">
                            {collectionDetails && collectionDetails.nft_handle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="mb-4">
                <Col>
                  <div className="mb-3 title-text">Attributes</div>
                  {attributes.map((data, index) => {
                    return (
                      <Row className="mb-4">
                        <Col md={5} lg={5}>
                          <Form.Control
                            type="text"
                            className="form-input-text"
                            placeholder="Enter Trait Name"
                            onChange={(e) =>
                              handleTraitNameChange(e.target.value, index)
                            }
                            value={data.trait_type.toLowerCase()}
                          />
                        </Col>
                        <Col md={5} lg={5}>
                          <Form.Control
                            type="text"
                            className="form-input-text"
                            placeholder="Enter Trait Value"
                            onChange={(e) =>
                              handleTraitValueChange(e.target.value, index)
                            }
                            value={data.value.toLowerCase()}
                          />
                        </Col>
                        <Col md={2} lg={2}>
                          {!selectedIndex[index] ? (
                            <button
                              className=""
                              style={{
                                background:
                                  "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                                borderRadius: "10px",
                                height: "37px",
                              }}
                              onClick={() => removeAttributes(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-trash"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#FFFFFF"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <line x1="4" y1="7" x2="20" y2="7" />
                                <line x1="10" y1="11" x2="10" y2="17" />
                                <line x1="14" y1="11" x2="14" y2="17" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                              </svg>
                            </button>
                          ) : (
                            <>
                              {attributes.length > 1 &&
                              attributes.length === lastIndex + 1 ? (
                                <div className="d-flex">
                                  <button
                                    className=""
                                    style={{
                                      background:
                                        "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                                      borderRadius: "10px",
                                      height: "37px",
                                    }}
                                    onClick={() => removeAttributes(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-tabler icon-tabler-trash"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="#FFFFFF"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <line x1="4" y1="7" x2="20" y2="7" />
                                      <line x1="10" y1="11" x2="10" y2="17" />
                                      <line x1="14" y1="11" x2="14" y2="17" />
                                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                  </button>
                                  <button
                                    className="ms-3"
                                    onClick={() => addAttributes(index)}
                                    style={{
                                      background:
                                        "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                                      borderRadius: "10px",
                                      height: "37px",
                                    }}
                                    disabled={
                                      data?.value?.length === 0 ||
                                      data?.trait_type?.length === 0 ||
                                      traitErrorMessage.length > 0
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Group_3743"
                                      data-name="Group 3743"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        id="Path_3760"
                                        data-name="Path 3760"
                                        d="M0,0H24V24H0Z"
                                        fill="none"
                                      />
                                      <circle
                                        id="Ellipse_682"
                                        data-name="Ellipse 682"
                                        cx="9"
                                        cy="9"
                                        r="9"
                                        transform="translate(3 3)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                      />
                                      <line
                                        id="Line_389"
                                        data-name="Line 389"
                                        x2="6"
                                        transform="translate(9 12)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                      />
                                      <line
                                        id="Line_390"
                                        data-name="Line 390"
                                        y2="6"
                                        transform="translate(12 9)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              ) : (
                                <button
                                  className=""
                                  onClick={() => addAttributes(index)}
                                  style={{
                                    background:
                                      "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                                    borderRadius: "10px",
                                    height: "37px",
                                  }}
                                  disabled={
                                    data?.value?.length === 0 ||
                                    data?.trait_type?.length === 0 ||
                                    traitErrorMessage.length > 0
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Group_3743"
                                    data-name="Group 3743"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      id="Path_3760"
                                      data-name="Path 3760"
                                      d="M0,0H24V24H0Z"
                                      fill="none"
                                    />
                                    <circle
                                      id="Ellipse_682"
                                      data-name="Ellipse 682"
                                      cx="9"
                                      cy="9"
                                      r="9"
                                      transform="translate(3 3)"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.5"
                                    />
                                    <line
                                      id="Line_389"
                                      data-name="Line 389"
                                      x2="6"
                                      transform="translate(9 12)"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.5"
                                    />
                                    <line
                                      id="Line_390"
                                      data-name="Line 390"
                                      y2="6"
                                      transform="translate(12 9)"
                                      fill="none"
                                      stroke="#fff"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                </button>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col md={5} lg={5}>
                      {traitErrorMessage.length > 0 && (
                        <span className="text-danger">{traitErrorMessage}</span>
                      )}
                    </Col>
                    <Col md={5} lg={5}>
                      {traitValueErrorMessage.length > 0 && (
                        <span className="text-danger">
                          {traitValueErrorMessage}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="my-3 mb-5">
                <Col md={12} className="d-flex justify-content-end flex-wrap">
                  {collectionDetails &&
                  address !==
                    collectionDetails.owner_publickey.toLowerCase() ? (
                    ""
                  ) : (
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btnGray"
                        onClick={() => history.push("/minted-nfts")}
                        style={{ width: "210px", padding: "0" }}
                        disabled={isLoading}
                      >
                        Back to Collection
                      </button>
                      <button
                        type="button"
                        className="btnGray ms-4"
                        onClick={handleSubmit}
                        disabled={
                          isLoading ||
                          traitErrorMessage.length > 0 ||
                          !dynamicNftImgURL ||
                          attributes?.[layerIndex]?.trait_type === "" ||
                          attributes?.[layerIndex]?.value === "" ||
                          traitValueErrorMessage.length > 0
                        }
                        style={{ width: "210px", padding: "0" }}
                      >
                        {isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-1"
                          />
                        )}
                        Update
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DynamicMintEditPage;
