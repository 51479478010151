import { inRange } from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import getProvider from "../methods/getProvider";

export const SolanaContext = React.createContext();

const SolanaContextProvider = ({ children }) => {
  const [logs, setLogs] = useState([]);
  const provider = getProvider();
  const [publicKey, setPublicKey] = useState(null);

  const createLog = useCallback(
    (log) => {
      return setLogs((logs) => [...logs, log]);
    },
    [setLogs]
  );

  useEffect(() => {
    if (!provider) return;

    provider.connect({ onlyIfTrusted: true }).catch(() => {});

    provider.on("connect", (publicKey) => {
      createLog({
        status: "success",
        method: "connect",
        message: `Connected to account ${publicKey.toBase58()}`,
      });
    });

    provider.on("disconnect", () => {
      createLog({
        status: "warning",
        method: "disconnect",
        message: "👋",
      });
    });

    provider.on("accountChanged", (publicKey) => {
      if (publicKey) {
        createLog({
          status: "info",
          method: "accountChanged",
          message: `Switched to account ${publicKey.toBase58()}`,
        });
      } else {
        createLog({
          status: "info",
          method: "accountChanged",
          message: "Attempting to switch accounts.",
        });

        provider.connect().catch((error) => {
          createLog({
            status: "error",
            method: "accountChanged",
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });

    return () => {
      provider.disconnect();
    };
  }, [createLog]);

  const handleConnect = useCallback(async () => {
    if (!provider) return;

    try {
      await provider.connect();
      if (provider.publicKey) {
        setPublicKey(provider.publicKey.toString());
      }
    } catch (error) {
      createLog({
        status: "error",
        method: "connect",
        message: error.message,
      });
    }
  }, [createLog]);

  return (
    <SolanaContext.Provider value={{ handleConnect, logs, publicKey }}>
      {children}
    </SolanaContext.Provider>
  );
};

export default SolanaContextProvider;
