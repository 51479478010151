import axios from "axios";
import React, { Component } from "react";
import AxiosInstance from "../AxiosRequest";

export const CollectionsContext = React.createContext();

class CollectionsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousNfts: {
        isLoading: false,
        total: 0,
        next: false,
        prev: false,
        data: [],
      },
    };
  }

  getPreviousNFTs = async (creator, page, limit, selectedChain) => {
    const { previousNfts: previousNfts } = this.state;
    this.setState({
      previousNfts: { ...previousNfts, isLoading: true },
    });
    try {
      const { data } = await AxiosInstance.post("/nftcreator/previousNFTs", {
        creator, page, limit, chain: selectedChain
      });
      this.setState({
        previousNfts: {
          isLoading: false,
          next: data.data.next,
          prev: data.data.prev,
          total: data.data.total || 0,
          data: data.data.data || [],
        },
      });
    } catch (err) {
      return err
    }
  }

  render() {
    const { children } = this.props;
    return (
      <CollectionsContext.Provider value={{ ...this, ...this.state }}>
        {children}
      </CollectionsContext.Provider>
    );
  }
}

export default CollectionsContextProvider;
