import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";

import { FC, useMemo } from "react";
import { clusterSolanaApiUrl, network } from "./constants";
import DeviceContextProvider from "./Context/DeviceContext";
import GridwidthContextProvider from "./Context/GridwidthContext";
import WalletContextProvider from "./Context/WalletContextProvider";
import CollectionsContextProvider from "./Context/CollectionsContext";
import Web3AuthContextProvider from "./Context/Web3AuthContext";
import { clusterApiUrl } from "@solana/web3.js";
import SolanaContextProvider from "./Context/SolanaContextProvider";

export const Providers: FC = ({ children }) => {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  return (
    <DeviceContextProvider>
      <GridwidthContextProvider>
        <Web3AuthContextProvider>
          <CollectionsContextProvider>
            <SolanaContextProvider>
              <WalletContextProvider>{children}</WalletContextProvider>
            </SolanaContextProvider>
          </CollectionsContextProvider>
        </Web3AuthContextProvider>
      </GridwidthContextProvider>
    </DeviceContextProvider>
  );
};
