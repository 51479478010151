import React, { useCallback, useEffect, useState } from "react";

export const authContext = React.createContext();

const Web3AuthContext = ({ children }) => {
  const [, setSelectedAddress] = useState();
  const [address, setAddress] = useState(window.ethereum?.selectedAddress);

  const addressChanged = useCallback((address) => {
    setSelectedAddress(address);
  }, []);

  useEffect(() => {
    const eventName = `accountsChanged`;

    if (!isMetaMaskInstalled()) {
      return;
    }

    const listener = ([selectedAddress]) => {
      setAddress(selectedAddress);
      addressChanged(selectedAddress);
    };

    window.ethereum.on(eventName, listener);

    return () => {
      window.ethereum.removeListener(eventName, listener);
    };
  }, [addressChanged]);

  const isMetaMaskInstalled = () => {
    return Boolean(window.ethereum);
  };

  const readAddress = async () => {
    const method = "eth_requestAccounts";

    const accounts = await window.ethereum.request({
      method,
    });
    setAddress(accounts[0]);
    addressChanged(accounts[0]);

    return accounts[0];
  };

  return (
    <authContext.Provider value={{ address, readAddress }}>
      {children}
    </authContext.Provider>
  );
};

export default Web3AuthContext;
