import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AxiosInstance from "../AxiosRequest";
import {copyText} from "../utils"

const NftSummary = ({ formik, state }) => {
  const [candyMachineId, setCandyMachineId] = useState("");
  const getData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getData?id=${state.collection._id}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setCandyMachineId(response.data.data.candyMachineId);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main className="mStepForm">
      <div className="step3 teamMemberPage">
        <Row>
          <Col xl={12} lg={12} md={12}>
            <div className="mt-3">
              <Row>
                <Col xl={9} lg={9} md={12}>
                  <div className="mb-4 topDetailArea">
                    <Row>
                      <Col md={12} lg={6}>
                        <div className="mb-2 textTitle">Name</div>
                        <div className="mb-4 textDetails">
                          {state.collection.collection_name}
                        </div>
                      </Col>

                      <Col md={12} lg={6}>
                        <div className="mb-2 textTitle">NFT Count</div>
                        <div className="mb-4 textDetails">
                          {state.collection.no_of_nfts}
                        </div>
                      </Col>
                      <Col md={12} lg={6}>
                        <div className="mb-2 textTitle">Handle</div>
                        <div className="mb-4 textDetails">
                          {state.collection.nft_handle}
                        </div>
                      </Col>
                      <Col md={12} lg={6}>
                        <div className="mb-2 textTitle">Mint URL</div>
                        <div className="mb-4 d-flex align-items-center">
                          <Link
                            className="textDetails"
                            to={`/${state.collection.nft_handle}/mint`}
                          >
                            {state.collection?.collection_name
                              ? state.collection?.collection_name
                              : ""}
                          </Link>
                          <button
                            className="btn copy-icon ms-1 mt-1"
                            onClick={() => {
                              copyText(
                                `${process.env.REACT_APP_WEB_URL}/${state.collection.nft_handle}/mint`
                              );
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#27c2e1" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <rect x="8" y="8" width="12" height="12" rx="2" />
                              <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                            </svg>
                          {/* <img className="ms-2" width={18} src={CopyIcon} alt="" /> */}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <p className="mt-3 textTitle">
                          {formik.values.projectDescription}
                        </p>
                      </Col>
                      <Col md={12}>
                        <button
                          className="btnGray mt-4"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          Back to Generator
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </main>
  );
};

export default NftSummary;
