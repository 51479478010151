import * as anchor from "@project-serum/anchor";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";

export const ONE_SOL = 1e9;

export const depositSOL = async(
    connection,
    from,
    to,
    amount
) => {
    try {
        if (!from.publicKey) throw new WalletNotConnectedError();
        const toPublicKey = new anchor.web3.PublicKey(to);

        const transaction = new anchor.web3.Transaction().add(
            anchor.web3.SystemProgram.transfer({
                fromPubkey: from.publicKey,
                toPubkey: toPublicKey,
                lamports: new anchor.BN(amount * ONE_SOL)
            })
        );
    
        transaction.feePayer = await from.publicKey;
        let blockhashObj = await connection.getRecentBlockhash();
        transaction.recentBlockhash = await blockhashObj.blockhash;
        let signed = await from.signTransaction(transaction);
        let signature = await connection.sendRawTransaction(signed.serialize());
        const confirmation = await connection.confirmTransaction(signature);

        if(confirmation.value.confirmationStatus === "confirmed") {
            return signature;
        } else {
            return false;
        }
    } catch(err) {
        throw err;
    }
}