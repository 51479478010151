import React from "react";
import UploadContent from "./UploadContent";
import VidAudDocUpload from "./VidAudDocUpload";

const ParentUpload = ({
  formik,
  state,
  setState,
  imageFile,
  setImageFile,
  subMenuIndex,
  contentMenu,
  setLayerErrorMessage
}) => {
  return(
  <div>
    {
      state.collection.type === "layered" ?
        <UploadContent
          formik={formik}
          state={state}
          setState={setState}
          imageFile={imageFile}
          setImageFile={setImageFile}
          subMenuIndex={subMenuIndex}
          contentMenu={contentMenu}
          setLayerErrorMessage={setLayerErrorMessage}
        />
        :
        <VidAudDocUpload 
          formik={formik}
        />
    }
  </div>)
};

export default ParentUpload;
