import React, { useEffect, useState } from "react";
import { clusterSolanaApiUrl } from "../constants";
import * as anchor from "@project-serum/anchor";
import { useWallet } from "@solana/wallet-adapter-react";
import moment from "moment";

export const BalanceContext = React.createContext();

const BalanceContextProvider = ({ children }) => {
  const [mainBalance, setMainBalance] = useState(0);
  const [date, setDate] = useState(moment().valueOf());
  const connection = new anchor.web3.Connection(clusterSolanaApiUrl);
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.publicKey) {
      connection.getBalance(wallet.publicKey).then(function (value) {
        setMainBalance(value / 1000000000);
      });
    }
  }, [wallet, date]);

  const refreshBalance = () => setDate(moment().valueOf());

  return (
    <BalanceContext.Provider value={{ mainBalance, refreshBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceContextProvider;
