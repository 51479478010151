import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AppRoutes from "./AppRoutes";
import NFTCreator from "./NFTCreator";
import { Providers } from "./provider";

function App() {
  const [isValid, setIsValid] = useState(false);
  const [sitePassword, setSitePassword] = useState("");
  const checkPassord = () => {
    if (sitePassword === "ZH0ThNjjaeTLxV9") {
      localStorage.setItem("valid", "true");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("valid") === "true") setIsValid(true);
  }, []);
  return (
    <>
      {isValid ? (
        <Providers>
          <AppRoutes />
        </Providers>
      ) : (
        <Fragment>
          <Modal
            show={true}
            onHide={() => {}}
            backdrop="static"
            keyboard={false}
            style={{ background: "#020202" }}
          >
            <Modal.Body style={{ background: "#1c1a1f" }}>
              <input
                type={"password"}
                className="w-100 form-input-text"
                placeholder="Enter Password"
                value={sitePassword}
                onChange={(e) => setSitePassword(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer style={{ background: "#1c1a1f" }}>
              <button
                className="btnGray w-100"
                type="button"
                onClick={checkPassord}
              >
                Login
              </button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      )}{" "}
    </>
  );
}

export default App;
