import { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import AxiosInstance from "../AxiosRequest";
import { authContext } from "../Context/Web3AuthContext";
import icCamera from "../assets/images/Camera.svg";
import axios from "axios";
import { dataURLtoFile, jsontoFile } from "../NFTCreator/CommonMethode";
import { cloneDeep } from "lodash";
import {
  REACT_APP_PINATA_API_KEY,
  REACT_APP_PINATA_API_SECRET,
  REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
  REACT_APP_PINATA_PIN_JSON_TO_IPFS_URL,
} from "../constants";
import Web3 from "web3";
import Nft from "../contract/Nft.json";
import {toast, ToastContainer} from "react-toastify";

import { DeviceContext } from "../Context/DeviceContext";
import placeHolder from "../assets/images/placeholder1.png";
import { WalletContext } from "../Context/WalletContextProvider";
import DynamicMintEditPage from "./DynamicMintEdit";

const headers = {
  pinata_api_key: REACT_APP_PINATA_API_KEY,
  pinata_secret_api_key: REACT_APP_PINATA_API_SECRET,
  "Content-Type": "multipart/form-data",
};

const MintEditPage = () => {
  const history = useHistory();
  const {
    getWalletConnectDropDown,
    getWalletButtonBasedSelectedChain,
    selectedChain,
    connectWallet,
  } = useContext(WalletContext);
  const { address } = useContext(authContext);

  const [collectionDetails, setCollectionDetails] = useState();
  const [collectionImg, setCollectionImg] = useState(null);
  const [collectionImgUrl, setCollectionImgUrl] = useState("");
  const params = useParams();
  const collectionRef = useRef(null);
  const newImageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [layerIndex, setLayerIndex] = useState(0);
  const [nftcreator, setNFTCreator] = useState();
  const [layerName, setLayerName] = useState();
  const [background_color, setBackgroundColor] = useState();
  const [attributes, setAttributes] = useState([]);
  const { device } = useContext(DeviceContext);
  const [isUpdateImageLoading, setIsUpdateImageLoading] = useState(false);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImageUrl, setNewImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isImageChange, setIsImageChange] = useState(false);
  const [newLayer, setNewLayer] = useState();
  const [newBackground, setNewBackground] = useState();
  const [newImage, setNewImage] = useState("");
  const [newDynamicNFTImage, setNewDynamicNFTImage] = useState("");

  const dynamicNftRef = useRef(null);
  const [dynamicNftImgURL, setDynamicNFTImgURL] = useState();
  const [isShowAttributesModal, setIsShowAttributesModal] = useState(false);
  const [isNewLayerAdded, setIsNewLayerAdded] = useState(false);
  const [traitErrorMessage, setTraitErrorMessage] = useState("");
  const [traitValueErrorMessage, setTraitValueErrorMessage] = useState("");

  useEffect(() => {
    if (params?.nft_handle && params.token_id) {
      getData();
    }
  }, [params]);

  useEffect(() => {
    if (params?.nft_handle) {
      getNFTCreatorData();
    }
  }, [params]);

  const handleCollectionImage = (e) => {
    if (e.target.files[0]) {
      setCollectionImg(e.target.files[0]);
      setCollectionImgUrl(URL.createObjectURL(e.target.files[0]));
      setDynamicNFTImgURL(URL.createObjectURL(e.target.files[0]));
      collectionRef.current.value = "";
      setIsImageChange(true);
    }
  };

  const getNFTCreatorData = async () => {
    const response = await AxiosInstance.get(
      `/nftcreator/getDataBySymbolAndChain?nft_handle=${params.nft_handle}&chain=${selectedChain}`
    );
    if (response.status === 200) {
      if (response.data && response.data.data) {
        setNFTCreator(response.data.data);
      }
    }
  };

  const getData = async () => {
    const response = await AxiosInstance.post(
      "/nfts/getNFTByTokenIdAndSymbol",
      {
        token_id: params.token_id,
        nft_handle: params.nft_handle,
      }
    );
    if (response.status === 200) {
      setCollectionDetails(response.data.data);
      setIsImageChange(response.data.data?.is_image_change);
      setAttributes(response.data.data.attributes);
    }
  };

  const getRarityValue = async (layerName, background_color) => {
    let rarity = 0;
    for (let i = 0; i < nftcreator.layers.length; i++) {
      if (nftcreator.layers[i].layerName === layerName) {
        for (let j = 0; j < nftcreator.layers[i].attributes.length; j++) {
          if (
            nftcreator.layers[i].attributes[j].background_color ==
            background_color
          ) {
            rarity = nftcreator.layers[i].attributes[j].rarity;
          } else {
            rarity = 100;
          }
        }
      } else {
        rarity = 100;
      }
    }
    return rarity;
  };

  const getNFTImage = async (value, layerName) => {
    if (value !== "custom") {
      setNewDynamicNFTImage("");
      setIsUpdateImageLoading(true);
      let layers = [];
      let array = [];
      if (nftcreator?.layers?.length > 0) {
        for (let i = 0; i < attributes.length; i++) {
          layers.push({
            name: attributes[i].trait_type,
          });
          if (attributes[i].trait_type === layerName && value) {
            attributes[i].trait_type = layerName;
            attributes[i].value = value;
            attributes[i].rarity = await getRarityValue(layerName, value);
          } else {
            attributes[i] = attributes[i];
            attributes[i].rarity = await getRarityValue(
              attributes[i].trait_type,
              attributes[i].value
            );
          }
          array[i] = attributes[i];
          layers[i].attributes = attributes[i];
        }
      }
      // setAttributes([...array]);
      await AxiosInstance.post(
        `/nftcreator/getNftImage?folderName=${
          nftcreator.folderName.split("/")[2]
        }`,
        {
          layers: layers,
          id: nftcreator._id,
          token_id: collectionDetails.token_id,
        }
      );
      setCollectionImgUrl(
        `${process.env.REACT_APP_API_URL}/${
          nftcreator.folderName.split("/")[2]
        }/dynamicnfts/${collectionDetails.token_id}.png?${Date.now()}`
      );
      setIsUpdateImageLoading(false);
      setBackgroundColor(value);
    } else {
      setBackgroundColor(value);
    }
  };

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const handleSubmit = async () => {
    try {
      let isNetworkChange = false;
      console.log(
        window.ethereum.networkVersion,
        collectionDetails?.network_id
      );
      if (window.ethereum.networkVersion !== nftcreator?.network_id) {
        try {
          isNetworkChange = await connectWallet(
            nftcreator.network_id === process.env.REACT_APP_ETHEREUM_NETWORK_ID
              ? process.env.REACT_APP_ETHEREUM_CHAIN
              : process.env.REACT_APP_POLYGON_CHAIN,
            nftcreator.network_id,
            setIsLoading
          );
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          toast.error("Something went wrong");
        }
      } else {
        isNetworkChange = true;
      }
      if (isNetworkChange) {
        const data = await toDataURL(
          newDynamicNFTImage ? newDynamicNFTImage : collectionImgUrl
        );
        setIsLoading(true);
        const iFormData = new FormData();
        iFormData.append(
          "file",
          dataURLtoFile(data, `${collectionDetails.token_id}.png`),
          `${nftcreator.collection_name}_Assets/${collectionDetails.token_id}.png`
        );

        const iResponse = await axios({
          method: "post",
          url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
          data: iFormData,
          headers,
        });

        const jFormData = new FormData();

        if (iResponse.data) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/${
              nftcreator.folderName.split("/")[2]
            }/dynamicnfts/${collectionDetails.token_id}`
          );
          const nftMetadata = response.data;
          nftMetadata.image = `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`;

          let obj = {};
          obj = {
            image: `ipfs://${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`,
            name: `${nftcreator.collection_name} #${collectionDetails.token_id}`,
            attributes: attributes,
          };

          jFormData.append(
            "file",
            jsontoFile(nftMetadata, `${collectionDetails.token_id}`),
            `${nftcreator.collection_name}_Metadata/${collectionDetails.token_id}`
          );

          const jResponse = await axios({
            method: "post",
            url: REACT_APP_PINATA_PIN_FILE_TO_IPFS_URL,
            data: jFormData,
            headers,
          });
          // const metadata = await axios.get(
          //   `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}/${collectionDetails.token_id}.json`
          // );

          const web3 = new Web3(window.ethereum);
          const nftContract = new web3.eth.Contract(
            Nft.abi,
            nftcreator.contract_address
          );
          const tx = await nftContract.methods
            .SetTokenURI(
              collectionDetails.token_id,
              `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`
            )
            .send({
              from: address,
            });
          console.log(tx);
          if (tx) {
            await AxiosInstance.post("/nfts/update", {
              id: collectionDetails._id,
              metadata_url: `https://gateway.pinata.cloud/ipfs/${jResponse.data.IpfsHash}`,
              attributes: response.data.attributes,
              image_url: `https://gateway.pinata.cloud/ipfs/${iResponse.data.IpfsHash}/${collectionDetails.token_id}.png`,
              is_image_change: isImageChange,
            });
            toast.success("NFT Updated Successfully");
            history.push("/minted-nfts");
          }
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleError = (e) => {
    e.target.src = placeHolder;
  };

  //This is called when user add new custom value
  const uploadDynamicNft = async (value, file) => {
    if (file && value) {
      let image = {};
      let layers = [];
      let array = collectionDetails.attributes;
      // let attributes = attributes;
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].trait_type === layerName) {
          image = new File([file], `${value}#100_${layerName}.png`, {
            type: file.type,
          });

          image.image_name = `${value}#100_${layerName}.png`;
        }
      }
      if (nftcreator?.layers?.length > 0) {
        for (let i = 0; i < attributes.length; i++) {
          layers.push({
            name: attributes[i].trait_type,
          });
          if (attributes[i].trait_type === layerName) {
            attributes[i].trait_type = layerName;
            attributes[i].value = value;
            attributes[i].rarity = 100;
          } else {
            attributes[i] = attributes[i];
            attributes[i].rarity = await getRarityValue(
              attributes[i].trait_type,
              attributes[i].value
            );
          }
          array[i] = attributes[i];
          layers[i].attributes = attributes[i];
        }
      }
      setAttributes(array);
      const formData = new FormData();
      formData.append("layers", JSON.stringify(layers));
      formData.append("id", nftcreator._id);
      formData.append("images", image);
      formData.append("token_id", collectionDetails.token_id);
      await AxiosInstance.post(
        `/nftcreator/uploadDynamicNft?folderName=${nftcreator.folderName}&type=${nftcreator.type}`,
        formData
      );
      setNewDynamicNFTImage("");
      setCollectionImgUrl(
        `${process.env.REACT_APP_API_URL}/${
          nftcreator.folderName.split("/")[2]
        }/dynamicnfts/${collectionDetails.token_id}.png?${Date.now()}`
      );
      setIsUpdateImageLoading(false);
    }
  };

  const handleNewImageChange = async (e) => {
    const file = e.target.files[0];
    if (e.target.files[0]) {
      const ext = file.type.split("/");
      if (
        ext[1] === "jpg" ||
        ext[1] === "jpeg" ||
        ext[1] === "png" ||
        ext[1] === "gif" ||
        ext[1] === "svg" ||
        ext[1] === "webp" ||
        ext[1] === "avif"
      ) {
        setErrorMessage("");
        setNewImageUrl(URL.createObjectURL(e.target.files[0]));
        newImageRef.current.value = "";
        setNewImageFile(file);
        // await uploadDynamicNft(background_color, file);
      }
    } else {
      setErrorMessage("Only accept image");
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (e.target.files[0]) {
      const ext = file.type.split("/");
      if (
        ext[1] === "jpg" ||
        ext[1] === "jpeg" ||
        ext[1] === "png" ||
        ext[1] === "gif" ||
        ext[1] === "svg" ||
        ext[1] === "webp" ||
        ext[1] === "avif"
      ) {
        setErrorMessage("");
        setNewImage(URL.createObjectURL(e.target.files[0]));
        newImageRef.current.value = "";
        if (newBackground && newLayer) {
          await uploadDynamicNftWithLayer(newBackground, file);
        }
      }
    } else {
      setErrorMessage("Only accept image");
    }
  };

  //This is called when user add new custom layer
  const uploadDynamicNftWithLayer = async (value, file) => {
    setIsUpdateImageLoading(true);
    let layers = [];
    for (let i = 0; i < attributes.length; i++) {
      layers.push({
        name: attributes[i].trait_type,
      });
      attributes[i].rarity = await getRarityValue(
        attributes[i].trait_type,
        attributes[i].value
      );
      layers[i].attributes = attributes[i];
    }
    let newAttributes = {};
    newAttributes.trait_type = newLayer;
    newAttributes.value = value;
    newAttributes.rarity = 100;
    layers.push({
      name: newLayer,
      attributes: newAttributes,
    });
    attributes.push(newAttributes);
    let image = {};
    image = new File([file], `${value}#100_${newLayer}.png`, {
      type: file.type,
    });

    image.image_name = `${value}#100_${newLayer}.png`;
    setAttributes(attributes);
    setNewBackground("");
    setNewLayer("");
    setIsNewLayerAdded(false);
    const formData = new FormData();
    formData.append("layers", JSON.stringify(layers));
    formData.append("id", nftcreator._id);
    formData.append("images", image);
    formData.append("token_id", collectionDetails.token_id);
    await AxiosInstance.post(
      `/nftcreator/uploadDynamicNft?folderName=${nftcreator.folderName}&type=${nftcreator.type}`,
      formData
    );
    setDynamicNFTImgURL("");
    setNewDynamicNFTImage(
      `${process.env.REACT_APP_API_URL}/${
        nftcreator.folderName.split("/")[2]
      }/dynamicnfts/${collectionDetails.token_id}.png?${Date.now()}`
    );
    setIsUpdateImageLoading(false);
  };

  const addAttributesModal = (layerName, index) => {
    setLayerName(layerName);
    setIsShowAttributesModal(true);
    setNewImageUrl("");
  };

  const removeAttributes = async (index) => {
    let array = attributes;
    array.splice(index, 1);
    setAttributes([...array]);
    setNewImage("");
    await getNFTImage();
  };

  const addAttributes = () => {
    setNewImage("");
    setIsNewLayerAdded(true);
  };

  const addNewLayer = (value) => {
    setNewLayer(value);
    const regex = /[~`!@_#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/;
    if (regex.test(value)) {
      setTraitErrorMessage("Please do not add any special symbols");
      return;
    } else {
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].trait_type === value) {
          setTraitErrorMessage("Please enter different trait name.");
          return;
        }
      }
    }
    setTraitErrorMessage("");
  };

  const addNewValue = (value) => {
    const regex = /[~`!@_#$%^&*()+={}\[\];:\'\"<>.,\/\\\?]/;
    setNewBackground(value);
    if (regex.test(value)) {
      setTraitValueErrorMessage("Please do not add any special symbols");
    } else {
      setTraitValueErrorMessage("");
    }
  };

  return (
    <div className="nft-creator">
      <ToastContainer />
      {!address ? (
        <div
          className="mx-5 d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {getWalletConnectDropDown()}
          {getWalletButtonBasedSelectedChain()}
        </div>
      ) : (
        <>
          <div
            style={{
              maxWidth: "14000px",
              paddingBottom: "10px",
              paddingTop: "120px",
              minHeight: "100vh",
            }}
          >
            <div className="container mt-5">
              <div
                className="collection-details mb-5"
                style={{ minHeight: "200px" }}
              >
                <Row>
                  <Col xl={3} lg={3} sm={12} className="d-flex">
                    {isUpdateImageLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          height: "280px",
                          width: "280px",
                          border: "1px solid #C626F2",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                        <span className="ms-2 text-white">processing</span>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="file-button d-flex justify-content-end align-items-end"
                          type="button"
                          style={{
                            backgroundImage: `url(${
                              dynamicNftImgURL
                                ? dynamicNftImgURL
                                : newDynamicNFTImage
                                ? newDynamicNFTImage
                                : collectionImgUrl
                                ? collectionImgUrl
                                : collectionDetails?.image_url
                                ? collectionDetails.image_url
                                : placeHolder
                            })`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "280px",
                            height: "280px",
                            borderRadius: "35px",
                            padding: "30px",
                          }}
                          onClick={() => collectionRef.current.click()}
                        >
                          <img src={icCamera} alt="..." className="plus-icon" />
                        </button>

                        <input
                          type={"file"}
                          ref={collectionRef}
                          accept="image/*"
                          onChange={handleCollectionImage}
                          className="hidden-file"
                        />
                      </div>
                    )}
                  </Col>
                  <Col xl={7} lg={7} sm={12} className="ms-4">
                    <div className="mb-4">
                      <div className="d-flex collection-description-details">
                        <div>
                          {device !== "mobile" && (
                            <div className="nft-name mb-1">
                              {collectionDetails && collectionDetails.name}
                            </div>
                          )}
                          <div className="label-name">
                            {collectionDetails && collectionDetails.nft_handle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Row className="mb-2">
                  <Col md={5} lg={5}>
                    <Form.Label>Traits</Form.Label>
                  </Col>
                  <Col md={5} lg={5}>
                    <Form.Label>Value</Form.Label>
                  </Col>
                  <Col md={2} lg={2}>
                    <Form.Label>Action</Form.Label>
                  </Col>
                </Row>
                {attributes?.length > 0 &&
                  attributes.map((data, i) => (
                    <Row className="">
                      <Col md={5} lg={5}>
                        <Form.Control
                          type="text"
                          value={data.trait_type}
                          className="form-input-text mt-2 mb-2"
                          readOnly
                        />
                      </Col>
                      <Col md={5} lg={5} className="mb-3">
                        <Form.Control
                          as="select"
                          className="form-input-text mt-2 mb-2"
                          value={
                            background_color === "custom"
                              ? "custom"
                              : attributes?.[i]?.value
                              ? attributes[i].value
                              : data.value
                          }
                          onChange={(e) => {
                            getNFTImage(e.target.value, data.trait_type);
                          }}
                        >
                          <>
                            <option disabled selected>
                              Select Value
                            </option>
                            <>
                              {nftcreator?.layers?.map(
                                (layer, j) =>
                                  data.trait_type === layer.layerName &&
                                  nftcreator.layers[j].attributes.map(
                                    (data, i) => {
                                      return (
                                        <option
                                          key={`value ${i}`}
                                          selected={
                                            attributes[i]?.value ==
                                            data.background_color
                                          }
                                          value={data.background_color}
                                        >
                                          {data.background_color}
                                        </option>
                                      );
                                    }
                                  )
                              )}
                              <option
                                key={`value ${i}`}
                                selected={data.value}
                                value={data.value}
                              >
                                {data.value}
                              </option>
                            </>
                          </>
                        </Form.Control>
                      </Col>
                      <Col md={2} lg={2}>
                        <div className="d-flex">
                          <button
                            className="mt-2"
                            onClick={() =>
                              addAttributesModal(data.trait_type, i)
                            }
                            style={{
                              background:
                                "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                              borderRadius: "10px",
                              height: "37px",
                            }}
                            disabled={isLoading}
                          >
                            <OverlayTrigger
                              key={"top"}
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`} placement="top">
                                  Add New attribute
                                </Tooltip>
                              }
                              className="w-100"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="Group_3743"
                                data-name="Group 3743"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  id="Path_3760"
                                  data-name="Path 3760"
                                  d="M0,0H24V24H0Z"
                                  fill="none"
                                />
                                <circle
                                  id="Ellipse_682"
                                  data-name="Ellipse 682"
                                  cx="9"
                                  cy="9"
                                  r="9"
                                  transform="translate(3 3)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_389"
                                  data-name="Line 389"
                                  x2="6"
                                  transform="translate(9 12)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_390"
                                  data-name="Line 390"
                                  y2="6"
                                  transform="translate(12 9)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </OverlayTrigger>
                          </button>
                          {attributes.length > 1 && (
                            <button
                              className="mt-2 ms-4"
                              onClick={() => removeAttributes(i)}
                              style={{
                                background:
                                  "linear-gradient(90deg, #7821EB 0%, #C625EA 100%)",
                                borderRadius: "10px",
                                height: "37px",
                              }}
                              disabled={isLoading}
                            >
                              <OverlayTrigger
                                key={"right"}
                                placement="right"
                                overlay={
                                  <Tooltip id={`tooltip-top`} placement="right">
                                    Remove Layer
                                  </Tooltip>
                                }
                                className="w-100"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="icon icon-tabler icon-tabler-trash"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#FFFFFF"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <line x1="4" y1="7" x2="20" y2="7" />
                                  <line x1="10" y1="11" x2="10" y2="17" />
                                  <line x1="14" y1="11" x2="14" y2="17" />
                                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg>
                              </OverlayTrigger>
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Row>
              <Row>
                {isNewLayerAdded && (
                  <>
                    <Row className="mb-4">
                      <Col md={5} lg={5}>
                        <Form.Control
                          type="text"
                          className="form-input-text"
                          placeholder="Enter Trait Name"
                          onChange={(e) => addNewLayer(e.target.value)}
                          value={newLayer}
                        />
                      </Col>
                      <Col md={5} lg={5}>
                        <Form.Control
                          type="text"
                          className="form-input-text"
                          placeholder="Enter Trait Value"
                          onChange={(e) => addNewValue(e.target.value)}
                          value={newBackground}
                        />
                      </Col>
                      <Col md={2} lg={2}>
                        <button
                          className="file-button d-flex justify-content-end align-items-end"
                          type="button"
                          style={{
                            backgroundImage: `url(${newImage})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            width: "80px",
                            height: "80px",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                          onClick={() => newImageRef.current.click()}
                        >
                          <img src={icCamera} alt="..." className="plus-icon" />
                        </button>

                        <input
                          type={"file"}
                          ref={newImageRef}
                          accept="image/*"
                          onChange={handleImageChange}
                          className="hidden-file"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5} lg={5}>
                        {traitErrorMessage.length > 0 && (
                          <span className="text-danger">
                            {traitErrorMessage}
                          </span>
                        )}
                      </Col>
                      <Col md={5} lg={5}>
                        {traitValueErrorMessage.length > 0 && (
                          <span className="text-danger">
                            {traitValueErrorMessage}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Row>
              <Row className="mb-5 mt-2">
                <Col md={2} lg={2}>
                  <button
                    className="btnGray"
                    onClick={() => {
                      setIsNewLayerAdded(true);
                      setNewImage("");
                    }}
                    disabled={
                      isLoading ||
                      (isNewLayerAdded &&
                        (!newLayer || !newBackground || !newImage))
                    }
                  >
                    Add New Layer
                  </button>
                </Col>
              </Row>
              <Row className="my-3 mb-5">
                <Col md={12} className="d-flex justify-content-end flex-wrap">
                  {collectionDetails &&
                  address !==
                    collectionDetails.owner_publickey.toLowerCase() ? (
                    ""
                  ) : (
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btnGray"
                        onClick={() => history.push("/minted-nfts")}
                        style={{ width: "210px", padding: "0" }}
                        disabled={isLoading}
                      >
                        Back to Collection
                      </button>
                      <button
                        type="button"
                        className="btnGray ms-4"
                        onClick={handleSubmit}
                        disabled={
                          isLoading ||
                          (isNewLayerAdded &&
                            (!newLayer || !newBackground || !newImage))
                        }
                        style={{ width: "210px", padding: "0" }}
                      >
                        {isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-1"
                          />
                        )}
                        Update
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {isShowAttributesModal && (
        <Modal
          show={isShowAttributesModal}
          centered
          onHide={() => setIsShowAttributesModal(false)}
          className="preview-nft-metadata-model"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center mx-auto">
              Add Attributes For {layerName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <div>
                <button
                  className="file-button d-flex justify-content-end align-items-end"
                  type="button"
                  style={{
                    backgroundImage: `url(${newImageUrl})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "80px",
                    height: "80px",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() => newImageRef.current.click()}
                >
                  <img src={icCamera} alt="..." className="plus-icon" />
                </button>

                <input
                  type={"file"}
                  ref={newImageRef}
                  accept="image/*"
                  onChange={handleNewImageChange}
                  className="hidden-file"
                />
              </div>
              <div className="ms-4 mt-2">
                <Form.Control
                  type="text"
                  className="form-input-text"
                  placeholder="Enter Trait Value"
                  onChange={(e) => setBackgroundColor(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button
                className="btnGray"
                onClick={() => {
                  uploadDynamicNft(background_color, newImageFile);
                  setIsShowAttributesModal(false);
                }}
                disabled={!background_color || !newImageFile}
              >
                Submit
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default MintEditPage;
